import * as loadingActions from 'actions/Loading';

export default function reducer(
  state = {
    loading: false,
    progress: 0,
  },
  action
) {
  if (action.type === loadingActions.SET_PROGRESS) return { ...state, progress: action.payload };
  if (action.type === loadingActions.SET_LOADING) return { ...state, loading: action.payload };
  if (action.type.includes('TOKEN')) return state;
  if (action.type.includes('REQUEST') && !(action.meta && action.meta.skipLoading)) {
    return { ...state, loading: true };
  }
  if (action.type.includes('SUCCESS')) return { ...state, loading: false, progress: 0 };
  if (action.type.includes('FAILURE')) return { ...state, loading: false, progress: 0 };
  return state;
}
