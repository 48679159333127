import React, { Fragment } from 'react';

// import ASB from 'assets/documents/FVS.ASB.V1.pdf';
import Verwendung from 'assets/documents/FVS.VerwendungVonCookies.pdf';

class AdminFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <div className='app-footer'>
          <div className='app-footer__inner'>
            <div className='app-footer-left'>
              <a className='app-footer__link' target='_blank' rel='noopener noreferrer' href='https://forumviasanitas.org/kontakt/#mitglied-werden'>
                AGB |{' '}
              </a>
              <a
                className='app-footer__link'
                target='_blank'
                rel='noopener noreferrer'
                // href={DatenShutz}
                href='https://service.forumviasanitas.org/datenschutz'
              >
                Datenschutz |{' '}
              </a>
              <a
                className='app-footer__link'
                target='_blank'
                rel='noopener noreferrer'
                // href={Impressum}
                href='https://service.forumviasanitas.org/impressum'
              >
                Impressum |{' '}
              </a>{' '}
              <a className='app-footer__link' target='_blank' rel='noopener noreferrer' href={Verwendung}>
                Verwendung von Cookies
              </a>
            </div>
            <div className='app-footer-center'></div>
            <div className='app-footer-right'>Copyright 2022 Forum via Sanitas</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AdminFooter;
