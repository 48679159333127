import { createStore, combineReducers, applyMiddleware } from "redux";
import reducers from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();
export default function configureStore() {
  const store = createStore(
    combineReducers({
      ...reducers,
    }),
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );
  store.runSaga = sagaMiddleware.run;
  return store;
}
