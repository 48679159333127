import axios from 'axios';
import querystring from 'querystring';
import jwtDecode from 'jwt-decode';
import { get, omit } from 'lodash';
import uploadProcessing from 'utils/uploadProcessing';
import { setLoading, setProgress } from 'actions/Loading';
import { store } from '../';
import { checkTokenExpiration } from 'utils/token';

import {
  userServiceUrl,
  trainingServiceUrl,
  keycloakUrl,
  realm,
  clientId,
  dmsUrl,
  dmsApiVersion,
  userApiVersion,
  trainingApiVersion,
  messageServiceUrl,
  messageApiVersion,
  productRegistrationServiceUrl,
} from 'config/environment';

import { flatDataToTreeNew } from 'utils/documentTreeHelper';
import { TYPES } from './constants';

const getFlowId = () => {
  return Math.random()
    .toString()
    .substr(2);
};

const getErrorMessage = (res, defaultMessage) => {
  if (res.localizedMessage) return res.localizedMessage;
  else if (res.message) return res.message;
  return defaultMessage;
};

axios.interceptors.request.use(
  async (config) => {
    config.onUploadProgress = function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //console.log(percentCompleted);
      store.dispatch(setProgress(percentCompleted));
    };

    const token = checkTokenExpiration();
    if (token && token !== 'expired') {
      config.headers['Authorization'] = 'Bearer ' + token;
      config.withCredentials = true;
    }
    return Promise.resolve(config);
  },
  (error) => {
    Promise.reject(error);
  }
);

const getProductFile = (key) => {
  return axios
    .get(`${dmsUrl}/api/product/file/${key}/${userApiVersion}/`, {
      responseType: 'blob',
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get users'),
      },
    }));
};

const getUsers = () => {
  return axios
    .get(`${userServiceUrl}/api/users/${userApiVersion}/`)
    .then((response) => {
      return {
        response: typeof response.data === 'string' ? [] : response.data,
      };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get users'),
      },
    }));
};

const getFilteredUsers = (data) => {
  let requestUrl = `${userServiceUrl}/api/users/filter/${userApiVersion}/?size=${data.size || 100}&page=${data.page || 0}`;
  if (data.userContains) requestUrl += `&userContains=${get(data, 'userContains', '')}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${get(data, 'sortDirection', '')}&sort.${data.sortBy}.order=0`;

  return axios
    .get(requestUrl)
    .then((response) => {
      return {
        response: typeof response.data === 'string' ? [] : response.data,
      };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get users'),
      },
    }));
};

const getUser = (id) => {
  return axios
    .get(`${userServiceUrl}/api/users/${id}/${userApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get user'),
      },
    }));
};

const getLicenseeClubs = () => {
  return axios
    .get(`${userServiceUrl}/api/licensee/clubs/${userApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get licensee clubs'),
      },
    }));
};

const createUserKeycloak = (data) => {
  return axios
    .post(`${userServiceUrl}/secure/customers/create/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create user'),
      },
    }));
};

const createUser = async (data) => {
  return axios
    .post(`${userServiceUrl}/api/users/v1.1/`, omit(data, 'options'))
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create user'),
      },
    }));
};

const updateUser = async (data) => {
  const payload = omit(data, ['address', 'options']);
  return axios
    .put(`${userServiceUrl}/api/user-detail/${userApiVersion}/`, payload)
    .then((response) => {
      if (get(data, 'address')) {
        return updateUserAddress({
          ...get(data, 'address', {}),
          options: data.options,
          userId: payload.id,
        });
      } else {
        return {
          response: {
            ...response.data,
            options: data.options,
            userId: payload.id,
          },
        };
      }
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update user'),
      },
    }));
};

const updateUserStatus = async (data) => {
  return axios
    .put(`${userServiceUrl}/api/users/update-status-by-admin/v1.0/`, omit(data, 'options'))
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create user'),
      },
    }));
};

const deleteUser = (id) => {
  return axios
    .delete(`${userServiceUrl}/api/users/${id}/sensitive-data/${userApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to delete user'),
      },
    }));
};

const createUserDetail = async (data) => {
  return axios
    .post(`${userServiceUrl}/api/user-detail/${data.userId}/${userApiVersion}/`, omit(data, ['addresses']))
    .then((response) => {
      if (!data.addresses) return { response: response.data };
      return data.addresses.reduce(async (acc, el) => {
        const { response, error } = await createUserAddress(data.userId, {
          additionalData: '',
          legalType: '',
          town: '',
          type: '',
          ...el,
          userId: data.userId,
        });
        if (error)
          return {
            error: {
              ...get(error, 'response.data', {}),
              message: getErrorMessage(get(error, 'response.data', {}), 'Failed to create user address'),
            },
          };
        if (response) return { response: response.data };
      }, {});
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create user detail'),
      },
    }));
};

const updateUserDetail = (id, data) => {
  return axios
    .put(`${userServiceUrl}/api/user-detail/${id}/${userApiVersion}/`, omit(data, ['addresses', 'address']))
    .then((response) => {
      if (!data.addresses) return { response: response.data };
      return data.addresses.reduce(async (acc, el) => {
        const payload = {
          additionalData: '',
          legalType: '',
          town: '',
          type: '',
          ...el,
          userId: data.userId,
        };
        const { response, error } = el.id ? await updateUserAddress(data.userId, payload) : await createUserAddress(data.userId, payload);
        if (error)
          return {
            error: {
              ...get(error, 'response.data', {}),
              message: getErrorMessage(get(error, 'response.data', {}), 'Failed to update user address'),
            },
          };
        if (response) return { response: response.data };
      }, {});
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update user detail'),
      },
    }));
};

const deleteUserDetail = (data) => {
  return axios
    .delete(`${userServiceUrl}/api/user-detail/${data.userDetail.id}/${userApiVersion}/`)
    .then((response) => {
      return deleteUser(data.id);
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to delete user'),
      },
    }));
};

const getClubs = () => {
  return axios
    .get(`${userServiceUrl}/api/club/${userApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get clubs'),
      },
    }));
};

const getMemberships = () => {
  return axios
    .get(`${userServiceUrl}/api/subscription/${userApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get memberships'),
      },
    }));
};

const updateMembership = (id, data) => {
  return axios
    .put(`${userServiceUrl}/api/membership/${id}/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get membership'),
      },
    }));
};

const createLicenseeKeycloak = (data) => {
  return axios
    .post(`${userServiceUrl}/secure/licensee/create/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create licensee'),
      },
    }));
};

const createLicensee = async (data) => {
  const { payload, keycloakPayload } = data;
  const { response, error } = await createLicenseeKeycloak(keycloakPayload);
  if (error) return Promise.resolve({ error });

  const licenseePayload = {
    ...payload,
    licenseeUuid: get(response, 'id'),
  };

  return axios
    .post(`${userServiceUrl}/api/licensee/${userApiVersion}/`, omit(licenseePayload, ['addresses']))
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create licensee'),
      },
    }));
};

const updateLicensee = (id, data) => {
  return axios
    .put(`${userServiceUrl}/api/licensee/${id}/${userApiVersion}/`, data)
    .then((response) => {
      if (get(data, 'address')) {
        return updateLicenseeAddress({
          ...get(data, 'address', {}),
          licenseeId: id,
        });
      } else {
        return { response: response.data };
      }
    })
    .catch((err) => {
      return {
        error: {
          ...get(err, 'response.data', {}),
          message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update licensee'),
        },
      };
    });
};

const deleteLicensee = (id) => {
  return axios
    .delete(`${userServiceUrl}/api/licensee/${id}/${userApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to delete licensee'),
      },
    }));
};

const getLicensees = () => {
  return axios
    .get(`${userServiceUrl}/api/licensee/${userApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get licensees'),
      },
    }));
};

const getLicensee = (id) => {
  return axios
    .get(`${userServiceUrl}/api/licensee/${id}/${userApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get licensee'),
      },
    }));
};

const createLicenseeAddress = (id, data) => {
  return axios
    .post(`${userServiceUrl}/api/address/licensee/${id}/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create licensee address'),
      },
    }));
};

const createClubAddress = (id, data) => {
  return axios
    .post(`${userServiceUrl}/api/address/club/${id}/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create club address'),
      },
    }));
};

const createUserAddress = (id, data) => {
  return axios
    .post(`${userServiceUrl}/api/address/user/${id}/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create user address'),
      },
    }));
};

const updateAddress = (id, data) => {
  return axios
    .put(`${userServiceUrl}/api/address/${id}/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update address'),
      },
    }));
};

const updateUserAddress = (data) => {
  return axios
    .put(`${userServiceUrl}/api/address/user/${userApiVersion}/`, data)
    .then((response) => ({
      response: {
        ...response.data,
        options: data.options,
        userId: data.userId,
      },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update user address'),
      },
    }));
};

const updateLicenseeAddress = (data) => {
  return axios
    .put(`${userServiceUrl}/api/address/licensee/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update licensee address'),
      },
    }));
};

const refreshToken = (token) => {
  const payload = querystring.stringify({
    client_id: clientId,
    refresh_token: token,
    grant_type: 'refresh_token',
  });
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .post(`${keycloakUrl}/auth/realms/${realm}/protocol/openid-connect/token`, payload, config)
    .then((response) => {
      return {
        response: {
          ...get(response, 'data', {}),
          decoded: jwtDecode(get(response, 'data.access_token')),
          isRefresh: true,
        },
      };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Session expired'),
      },
    }));
};

const createLicenseeStudioInfo = (data) => {
  return axios
    .post(`${trainingServiceUrl}/api/studio-screen/licensee/${trainingApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create studio news'),
      },
    }));
};

const updateLicenseeStudioInfo = (id, data) => {
  if (data.id) delete data.id;
  return axios
    .put(`${trainingServiceUrl}/api/studio-screen/licensee/${id}/${trainingApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update studio news'),
      },
    }));
};

const getDefaultStudioInfo = () => {
  return axios
    .get(`${trainingServiceUrl}/api/studio-screen/default/${trainingApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get studio news'),
      },
    }));
};

const getLicenseeStudioInfo = () => {
  return axios
    .get(`${trainingServiceUrl}/api/studio-screen/licensee/${trainingApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get studio news'),
      },
    }));
};

const convertTrainingVideo = (data) => {
  return axios
    .post(`${trainingServiceUrl}/api/training/video/${trainingApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to convert video'),
      },
    }));
};

const pushVideoCommand = (data) => {
  return axios
    .post(`${trainingServiceUrl}/api/video-control/command/${data}/${trainingApiVersion}/`, null)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to push video command'),
      },
    }));
};

const pushScoreCard = (data) => {
  return axios
    .post(`${trainingServiceUrl}/api/video-control/score-card/${data}/${trainingApiVersion}/`, null)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to push score card'),
      },
    }));
};

const pushTrainingVideo = (data) => {
  return axios
    .post(`${trainingServiceUrl}/api/video-control/${data}/${trainingApiVersion}/`, null)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to push training video'),
      },
    }));
};

const getComments = (id) => {
  return axios
    .get(`${userServiceUrl}/api/user-detail/${id}/${userApiVersion}/`)
    .then((response) => ({ response: get(response, 'data.comments', []) }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get comments'),
      },
    }));
};

const createComment = (id, data) => {
  return axios
    .post(`${userServiceUrl}/api/user-detail/comments/${id}/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create comment'),
      },
    }));
};

const updateComment = (id, data) => {
  return axios
    .put(`${userServiceUrl}/api/user-detail/comments/${id}/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update comment'),
      },
    }));
};

const deleteComment = (id) => {
  return axios
    .delete(`${userServiceUrl}/api/user-detail/comments/${id}/${userApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to delete comment'),
      },
    }));
};

const updateUserPassword = (data) => {
  return axios
    .put(`${userServiceUrl}/api/users/update-password/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update user password'),
      },
    }));
};

const updateUserPasswordByAdmin = (data) => {
  return axios
    .put(`${userServiceUrl}/api/users/update-password-by-admin/${userApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update user password'),
      },
    }));
};

const getAvatarLink = (data) => {
  return axios
    .get(`${userServiceUrl}/api/user-picture/download-link/${userApiVersion}/?userUUID=${data}`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get avatar download link',
      },
    }));
};

const uploadTeaserVideo = (data) => {
  const payload = new FormData();
  payload.append(
    'file',
    data,
    `${Math.random()
      .toString()
      .substr(2)}.mp4`
  );

  return axios
    .post(`${trainingServiceUrl}/api/mediathek/upload/teaser/${trainingApiVersion}/`, payload)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to upload teaser video'),
      },
    }));
};

const getPublicDocuments = () => {
  return axios
    .get(`${dmsUrl}/api/article/all/public/${dmsApiVersion}/`)
    .then((response) => {
      const documentsTree = flatDataToTreeNew(response.data);
      const wikisanitas = documentsTree.find((el) => el.name === 'Wikisanitas');
      const communityFolder = documentsTree.find((el) => el.name === 'Community');
      const campusFolder = documentsTree.find((el) => el.name === 'Campus');
      return {
        response: {
          list: response.data,
          tree: documentsTree,
          wikisanitas: wikisanitas,
          community: communityFolder,
          campus: campusFolder,
        },
      };
    })
    .catch((err, res) => {
      console.log('documents error', err);
      return {
        error: {
          ...get(err, 'response.data', {}),
          data: get(err, 'message'),
          message: 'Failed to get public documents',
        },
      };
    });
};

const getDocuments = (data = {}) => {
  let requestUrl = `${dmsUrl}/api/article_NEW/all/current/${dmsApiVersion}/?size=${data.size || 100}&page=${data.page || 0}`;
  if (data.contains) requestUrl += `&contains=${get(data, 'contains', '')}`;
  if (data.id) requestUrl += `&id=${get(data, 'id', '')}`;
  if (data.type) requestUrl += `&types=${get(data, 'type', '')}`;
  if (data.category && (data.type !== TYPES.PRODUCT)) requestUrl += `&category=${get(data, 'category', '')}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${get(data, 'sortDirection', '')}&sort.${data.sortBy}.order=0`;

  return axios
    .get(requestUrl)
    .then((response) => {
      return {
        response: {
          list: response.data.content,
          totalPages: response.data.totalPages || 1,
          totalElements: response.data.totalElements || 0,
        },
      };
    })
    .catch((err, res) => {
      return {
        error: {
          ...get(err, 'response.data', {}),
          data: get(err, 'message'),
          message: 'Failed to get the pages',
        },
      };
    });
};

const getDocumentsPublic = (data = {}) => {
  let requestUrl = `${dmsUrl}/api/article_public/all/current/${dmsApiVersion}/?size=${data.size || 100}&page=${data.page || 0}`;
  if (data.contains) requestUrl += `&contains=${get(data, 'contains', '')}`;
  if (data.id) requestUrl += `&id=${get(data, 'id', '')}`;
  if (data.type) requestUrl += `&types=${get(data, 'type', '')}`;
  if (data.category && (data.type === TYPES.NUTRITION || data.type === TYPES.KNOWLEDGE)) requestUrl += `&category=${get(data, 'category', '')}`;
  if (data.sortBy) requestUrl += `&sort.${data.sortBy}.direction=${get(data, 'sortDirection', '')}&sort.${data.sortBy}.order=0`;

  return axios
    .get(requestUrl)
    .then((response) => {
      return {
        response: {
          list: response.data.content,
          totalPages: response.data.totalPages || 1,
          totalElements: response.data.totalElements || 0,
        },
      };
    })
    .catch((err, res) => {
      return {
        error: {
          ...get(err, 'response.data', {}),
          data: get(err, 'message'),
          message: 'Failed to get the pages',
        },
      };
    });
};

const getBriefDocuments = () => {
  return axios
    .get(`${dmsUrl}/api/article/all/brief/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err, res) => {
      console.log('documents error', err);
      return {
        error: {
          ...get(err, 'response.data', {}),
          data: get(err, 'message'),
          message: 'Failed to get brief pages',
        },
      };
    });
};

const getUserDocuments = (id) => {
  return axios
    .get(`${dmsUrl}/api/article/all/current/${dmsApiVersion}/?customerUUID=${id}`)
    .then((response) => {
      return {
        response: response.data,
      };
    })
    .catch((err, res) => {
      console.log('documents error', err);
      return {
        error: {
          ...get(err, 'response.data', {}),
          data: get(err, 'message'),
          message: 'Failed to get user pages',
        },
      };
    });
};

const getSharedDocuments = () => {
  return axios
    .get(`${dmsUrl}/api/article/all/current/${dmsApiVersion}/?accessType=publicOrShared`)
    .then(async (response) => {
      const documentsTree = flatDataToTreeNew(response.data);
      const communityFolder = documentsTree.find((el) => el.name === 'Community');
      const campusFolder = documentsTree.find((el) => el.name === 'Campus');
      const wikisanitas = documentsTree.find((el) => el.name === 'Wikisanitas');
      const productsFolder = documentsTree.find((el) => el.name === 'Products');

      return {
        response: {
          list: response.data,
          tree: documentsTree,
          wikisanitas: wikisanitas,
          community: communityFolder,
          campus: campusFolder,
          productsFolder: productsFolder,
        },
      };
    })
    .catch((err, res) => {
      console.log('documents error', err);
      return {
        error: {
          ...get(err, 'response.data', {}),
          data: get(err, 'message'),
          message: 'Failed to get shared pages',
        },
      };
    });
};
// const getSharedDocuments = () => {
//   return axios
//     .get(`${ dmsUrl }/api/article/all/current/${ dmsApiVersion }/?accessType=shared`)
//     .then(async(response) => {
//       const publicDocumentsResponse = await getPublicDocuments();
//
//       const documentsTree = flatDataToTreeNew([...response.data, ...publicDocumentsResponse.response.list], true);
//       const communityFolder = documentsTree.find(el => el.name === "Community");
//       const campusFolder = documentsTree.find(el => el.name === "Campus");
//       const wikisanitas = documentsTree.find(el => el.name === "Wikisanitas");
//       const joinedCampus = {...campusFolder, children: [...campusFolder.children, ...publicDocumentsResponse.response.campus.children]};
//       const joinedWikisanitas = {...wikisanitas, children: [...wikisanitas.children, ...publicDocumentsResponse.response.wikisanitas.children]};
//       const joinedCommunity = {...communityFolder, children: [...communityFolder.children, ...publicDocumentsResponse.response.community.children]};
//
//       return {
//         response: {
//           list: [...response.data, ...publicDocumentsResponse.response.list],
//           tree: documentsTree,
//           wikisanitas: joinedWikisanitas,
//           community: joinedCommunity,
//           campus: joinedCampus,
//         }
//       };
//     })
//     .catch((err, res) => {
//       console.log("documents error", err);
//       return {
//         error: {
//           ...get(err, "response.data", {}),
//           data: get(err, 'message'),
//           message: "Failed to get shared documents"
//         }
//       };
//     });
// };

const getDocument = (id) => {
  return axios
    .get(`${dmsUrl}/api/article_NEW/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get page',
      },
    }));
};

const getDocumentPublic = (id) => {
  return axios
    .get(`${dmsUrl}/api/article_public/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get page',
      },
    }));
};

const getSubpage = (data) => {
  return axios
    .get(`${dmsUrl}/api/article_NEW/subpage/${data.id}/${data.mainArticleId}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get page',
      },
    }));
};

const createSubpage = (mainArticleId, data) => {
  return axios
    .post(`${dmsUrl}/api/article_NEW/subpage/${mainArticleId}/${dmsApiVersion}/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to create page',
      },
    }));
};

const updateSubpage = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/article_NEW/subpage/${id}/${dmsApiVersion}/`, data)
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to update page',
      },
    }));
};

const getDocumentByVersion = (data) => {
  return axios
    .get(`${dmsUrl}/api/article/version/${data.id}/${data.versionId}/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get page versions',
      },
    }));
};

const createDocument = (data) => {
  return axios
    .post(`${dmsUrl}/api/article_NEW/${dmsApiVersion}/`, omit(data, 'options'))
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to create page',
      },
    }));
};

const updateDocument = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/article_NEW/${id}/${dmsApiVersion}/`, omit(data, 'options'))
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to update page',
      },
    }));
};

const deleteDocument = (id) => {
  return axios
    .delete(`${dmsUrl}/api/article_NEW/${id}/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to delete page',
      },
    }));
};

function fileReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(reader.result);
    };

    reader.readAsArrayBuffer(file);
  });
}

const uploadDocumentFile = (data) => {
  const payload = new FormData();
  payload.append('file', data.file);

  return axios
    .post(`${dmsUrl}/api/article/upload/v1.0/`, payload, {
      headers: {
        'X-File-Length': data.file.size,
        'Content-Type': data.file.type,
      },
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to upload file'),
      },
    }));
};

const uploadPdfFile = (data) => {
  const payload = new FormData();
  payload.append('file', data.file);

  return axios
    .post(`${dmsUrl}/api/article/upload/pdf/v1.0/`, payload, {
      headers: {
        'X-File-Length': data.file.size,
        'Content-Type': data.file.type,
      },
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to upload file'),
      },
    }));
};

const uploadProductFile = (data) => {
  const payload = new FormData();
  payload.append('file', data);

  return axios
    .post(`${dmsUrl}/api/product/file/upload/v1.1/`, payload, {
      headers: {
        'X-File-Length': data.size,
      },
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to upload file'),
      },
    }));
};

const uploadVideo = (data) => {
  const payload = new FormData();
  payload.append('file', data.file);
  return axios
    .post(`${dmsUrl}/api/article/${data.id}/upload-video/async/v1.0/?convert=${data.convert}`, payload, {
      headers: {
        'X-File-Length': data.file.size,
      },
    })
    .then(async (response) => {
      if (data.convert) {
        const videoResult = await uploadProcessing(data.id);
        store.dispatch(setLoading(false));
        if (videoResult.compressStatus === 'FAILED') {
          throw new Error(videoResult.message);
        }
        return generateFileDownloadLink(videoResult.fileKey);
      } else {
        return generateFileDownloadLink(response.data.fileKey);
      }
    })
    .catch((err) => {
      console.log(err);
      return {
        error: {
          ...get(err, 'response.data', {}),
          message: getErrorMessage(get(err, 'response.data', {}), 'Failed to upload file'),
        },
      };
    });
};

const uploadDocumentEditorFile = (data) => {
  const payload = new FormData();
  payload.append('file', data.file);

  return axios
    .post(`${dmsUrl}/api/article/${data.id}/upload/v1.0/`, payload, {
      headers: {
        'X-File-Length': data.file.size,
      },
    })
    .then((response) => {
      return generateFileDownloadLink(response.data.keys[0]);
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to upload file'),
      },
    }));
};

const generateFileDownloadLink = (key) => {
  return axios
    .get(`${dmsUrl}/api/article/download-link/${key}/v1.0/`)
    .then((response) => {
      return { response: { url: response.data, key } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to upload file'),
      },
    }));
};

const batchUpdateDocumentPath = (data) => {
  return axios
    .put(`${dmsUrl}/api/article/${dmsApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Request failed',
      },
    }));
};

const createDocComment = (data) => {
  return axios
    .put(`${dmsUrl}/api/article/comment/${data.articleId}/${dmsApiVersion}/`, data.payload)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to create comment',
      },
    }));
};

const updateDocComment = (data) => {
  return axios
    .put(`${dmsUrl}/api/article/comment/${data.articleId}/${data.commentId}/${dmsApiVersion}/`, data.payload)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to update comment',
      },
    }));
};

const deleteDocComment = (data) => {
  return axios
    .delete(`${dmsUrl}/api/article/comment/${data.articleId}/${data.commentId}/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to delete comment',
      },
    }));
};

const moveDocument = (id, data) => {
  return axios({
    url: `${dmsUrl}/api/article/move/${id}/${dmsApiVersion}/`,
    method: 'post',
    data: data,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to move document',
      },
    }));
};

const copyDocument = (id) => {
  return axios({
    url: `${dmsUrl}/api/article/copy/${id}/${dmsApiVersion}/`,
    method: 'post',
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to move document',
      },
    }));
};

const createDocumentPermissions = (id, data) => {
  return axios
    .post(`${dmsUrl}/api/article/permissions/${id}/v1.1/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to create page pemissions',
      },
    }));
};

const addDocumentPermissions = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/article/permissions/${id}/v1.0/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to create page pemissions',
      },
    }));
};

const deleteDocumentPermissions = (id, data) => {
  return axios({
    url: `${dmsUrl}/api/article/permissions/${id}/v1.1/`,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to delete page pemissions',
      },
    }));
};

const getDocumentPermissions = (id) => {
  return axios
    .get(`${dmsUrl}/api/article/permissions/${id}/grouped-by-type/v1.0/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get page permissions',
      },
    }));
};

const getSurveys = () => {
  return axios
    .get(`${dmsUrl}/api/survey/all/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get surveys',
      },
    }));
};

const getSurvey = (id) => {
  return axios
    .get(`${dmsUrl}/api/survey/${id}/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get survey',
      },
    }));
};

const getUserSurveys = () => {
  return axios
    .get(`${dmsUrl}/api/user-survey/all/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get user surveys',
      },
    }));
};

const getUserCompletedSurveys = (id) => {
  return axios
    .get(`${dmsUrl}/api/user-survey/all/user-id/${id}/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get user surveys',
      },
    }));
};

const createSurvey = (data) => {
  return axios
    .post(`${dmsUrl}/api/survey/${dmsApiVersion}/`, omit(data, ['callback', 'options']))
    .then((response) => {
      if (data.callback) data.callback();

      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to create survey',
      },
    }));
};

const updateSurvey = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/survey/${id}/${dmsApiVersion}/`, omit(data, 'options'))
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to update survey',
      },
    }));
};

const submitSurvey = (data) => {
  return axios
    .post(`${dmsUrl}/api/user-survey/${dmsApiVersion}/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to submit survey',
      },
    }));
};

const uploadSurveyPdf = (data) => {
  return fileReader(data).then((result) => {
    return axios
      .post(`${dmsUrl}/api/survey/file/upload/${dmsApiVersion}/`, result, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => ({ response: response.data }))
      .catch((err) => ({
        error: {
          ...get(err, 'response.data', {}),
          message: 'Failed to upload file',
        },
      }));
  });
};

const generateDownloadLink = (s3id) => {
  return axios
    .get(`${dmsUrl}/api/survey/file/download-link/${s3id}/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to attach pdf',
      },
    }));
};

const attachSurveyPdf = (s3id, surveyId) => {
  const payload = {
    id: surveyId,
    s3id,
  };

  return axios
    .post(`${dmsUrl}/api/user-survey/attach_pdf/${dmsApiVersion}/`, payload)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to attach pdf',
      },
    }));
};

const deleteSurvey = (id) => {
  return axios
    .delete(`${dmsUrl}/api/survey/${id}/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to delete survey',
      },
    }));
};

const getProfile = () => {
  return axios
    .get(`${userServiceUrl}/api/users/self/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get profile',
      },
    }));
};

const updateProfile = async (data) => {
  const payload = omit(data, ['address']);
  return axios
    .put(`${userServiceUrl}/api/users/self/update/v1.0/`, payload)
    .then((response) => {
      if (get(data, 'address')) {
        return updateUserAddress({
          ...get(data, 'address', {}),
          userId: payload.userId,
        });
      } else {
        return { response: response.data };
      }
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to update password',
      },
    }));
};

const updatePassword = (data) => {
  return axios
    .put(`${userServiceUrl}/api/users/update-password/v1.0/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to update profile',
      },
    }));
};

const getGroups = () => {
  return axios
    .get(`${userServiceUrl}/api/permission-group/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get groups'),
      },
    }));
};

const getGroup = (groupUuid) => {
  return axios
    .get(`${userServiceUrl}/api/permission-group/${groupUuid}/${dmsApiVersion}/ `)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to load group'),
      },
    }));
};

const getGroupByName = (groupName) => {
  return axios
    .get(`${userServiceUrl}/api/permission-group/name/${groupName}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to load group'),
      },
    }));
};

const getUserGroups = (userUuid) => {
  return axios
    .get(`${userServiceUrl}/api/permission-group/user/${userUuid}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get user groups'),
      },
    }));
};

const getGroupUsers = (data) => {
  return axios
    .get(
      `${userServiceUrl}/api/users/filter/${dmsApiVersion}/?permissionGroupUuid=${data.groupUuid}&page=${data.page || 0}&size=${data.size || 300}&sort.firstName.direction=ASC&sort.firstName.order=0`
    )
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get permission groups',
      },
    }));
};

const createGroup = (data) => {
  return axios
    .post(`${userServiceUrl}/api/permission-group/${dmsApiVersion}/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create group'),
      },
    }));
};

const updateUserGroups = (data) => {
  return axios
    .post(`${userServiceUrl}/api/permission-group/user/${data.userUuid}/${dmsApiVersion}/`, data.groups)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update user groups'),
      },
    }));
};

const addUsersToGroup = (data) => {
  return axios
    .put(`${userServiceUrl}/api/permission-group/${data.groupUuid}/user/${dmsApiVersion}/`, data.users)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to add user'),
      },
    }));
};

const deleteGroup = (groupUuid) => {
  return axios
    .delete(`${userServiceUrl}/api/permission-group/${groupUuid}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to delete group'),
      },
    }));
};

const deleteUsersFromGroup = (groupUuid, users) => {
  return axios
    .delete(`${userServiceUrl}/api/permission-group/${groupUuid}/user/${dmsApiVersion}/`, {
      'X-Flow-Id': getFlowId(),
      data: users,
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get delete user'),
      },
    }));
};

const uploadUserImage = (data) => {
  const payload = new FormData();
  payload.append('file', data.file);
  return axios
    .post(`${userServiceUrl}/api/user-picture/upload/${userApiVersion}/?userId=${data.userId}`, payload, {
      headers: {
        'X-Flow-Id': getFlowId(),
      },
    })
    .then((response) => {
      return { response: { ...response.data, id: data.userId } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to upload user image'),
      },
    }));
};

const getUserImage = (data) => {
  return axios({
    url: `${userServiceUrl}/api/user-picture/download/${userApiVersion}/?userId=${data.userId}`,
    method: 'get',
    headers: {
      'X-Flow-ID': getFlowId(),
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get user image'),
      },
    }));
};

const deleteUserImage = (data) => {
  return axios({
    url: `${userServiceUrl}/api/user-picture/${userApiVersion}/?userId=${data.userId}`,
    method: 'delete',
    headers: {
      'X-Flow-ID': getFlowId(),
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to delete user image'),
      },
    }));
};

const getIp = () => {
  return axios({
    url: 'https://ipinfo.io/json?token=f9f6f33717912b',
    method: 'get',
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get ip',
      },
    }));
};

const registerUser = (data) => {
  return axios({
    url: `${userServiceUrl}/api/users/registration/${userApiVersion}/`,
    method: 'post',
    data: data,
    headers: {
      'X-Flow-Id': getFlowId(),
      'Accept-Language': 'de',
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.parameters.localizedMessage') || get(err, 'response.data.localizedMessage') || get(err, 'response.data.message', 'Failed to register user'),
      },
    }));
};

const activateUserAccount = (id) => {
  return axios({
    url: `${userServiceUrl}/api/users/activation/${userApiVersion}/?id=${id}`,
    method: 'get',
    headers: {
      'X-Flow-Id': getFlowId(),
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to activate user account',
      },
    }));
};

const requestPasswordReset = (data, origin) => {
  return axios({
    url: `${messageServiceUrl}/api/send-mail/forgot/${messageApiVersion}/?email=${data}${origin ? '&origin=' + origin : ''}`,
    method: 'post',
    headers: {
      'X-Flow-Id': getFlowId(),
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to reset password',
      },
    }));
};

const getProducts = () => {
  return axios
    .get(`${productRegistrationServiceUrl}/api/shop/product/${dmsApiVersion}/`, {
      'X-Flow-Id': getFlowId(),
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get products',
      },
    }));
};

const getProduct = (productNumber) => {
  return axios
    .get(`${productRegistrationServiceUrl}/api/shop/product/filter/${dmsApiVersion}/?productNumber=${productNumber}`, {
      'X-Flow-Id': getFlowId(),
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get product',
      },
    }));
};

const getProductMappings = (id) => {
  return axios
    .get(`${productRegistrationServiceUrl}/api/productregistration/mappings/product/${id}/${dmsApiVersion}/`, {
      'X-Flow-Id': getFlowId(),
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get product',
      },
    }));
};

const createProductMappings = (data) => {
  return axios
    .post(`${productRegistrationServiceUrl}/api/productregistration/mappings/${dmsApiVersion}/`, data, {
      'X-Flow-Id': getFlowId(),
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to map group to product',
      },
    }));
};

const deleteProduct = (data) => {
  return axios
    .delete(`${productRegistrationServiceUrl}/api/productregistration/${dmsApiVersion}/`, data, {
      'X-Flow-Id': getFlowId(),
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to delete product',
      },
    }));
};

// const deleteProductMappingss = (data) => {
//   return axios
//     .delete(`${ productRegistrationServiceUrl }/api/productregistration/${ dmsApiVersion }/`, data,{
//       'X-Flow-Id': getFlowId()
//     })
//     .then(response => {
//       return ({response: response.data})
//     })
//     .catch(err => ({
//       error: {
//         ...get(err, "response.data", {}),
//         message: "Failed to delete product mapping"
//       }
//     }))
// }

const deleteProductMappings = (data) => {
  return axios
    .delete(`${productRegistrationServiceUrl}/api/productregistration/mappings/${dmsApiVersion}/`, {
      'X-Flow-Id': getFlowId(),
      data,
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to delete product mapping',
      },
    }));
};

const checkFolderSize = (id) => {
  return axios
    .get(`${dmsUrl}/api/article/folder-size/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get folder size',
      },
    }));
};

const getProductPreview = (productNumber) => {
  return axios
    .get(`${dmsUrl}/api/product/productNumber/${dmsApiVersion}/?productNumber=${productNumber}`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get product preview'),
      },
    }));
};

const updateProductPreview = (data) => {
  return axios
    .put(`${dmsUrl}/api/product/${dmsApiVersion}/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to update product preview'),
      },
    }));
};

const createProductPreview = (data) => {
  return axios
    .post(`${dmsUrl}/api/product/${dmsApiVersion}/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create product preview'),
      },
    }));
};

const getAcademyProducts = () => {
  return axios
    .get(`${dmsUrl}/api/product/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get products'),
      },
    }));
};

const getVideos = () => {
  return axios
    .get(`${dmsUrl}/api/video-library/all/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get videos'),
      },
    }));
};

const getVideo = (id) => {
  return axios
    .get(`${dmsUrl}/api/video-library/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get video'),
      },
    }));
};

const createVideo = (data) => {
  const payload = new FormData();
  payload.append('file', data.file);
  payload.append('title', data.title);
  payload.append('description', data.description);
  payload.append('fileLength', data.fileLength);
  payload.append('duration', data.duration);

  return axios
    .post(`${dmsUrl}/api/video-library/${dmsApiVersion}/`, payload, {
      headers: {
        'X-File-Length': data.fileLength,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to create video'),
      },
    }));
};

const updateVideo = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/video-library/${id}/${dmsApiVersion}/`, data)
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get video'),
      },
    }));
};

const deleteVideo = (id) => {
  return axios
    .delete(`${dmsUrl}/api/video-library/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get video'),
      },
    }));
};

const getVideoLink = (id) => {
  return axios
    .get(`${dmsUrl}/api/article/download-link/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: getErrorMessage(get(err, 'response.data', {}), 'Failed to get video'),
      },
    }));
};

export default {
  getUsers,
  getUser,
  getFilteredUsers,
  createUser,
  updateUser,
  deleteUser,
  getAvatarLink,
  createUserKeycloak,
  createUserDetail,
  deleteUserDetail,
  getClubs,
  getMemberships,
  getLicensees,
  createLicenseeKeycloak,
  createLicensee,
  updateLicensee,
  deleteLicensee,
  getLicensee,
  createLicenseeAddress,
  createClubAddress,
  createUserAddress,
  updateAddress,
  refreshToken,
  updateUserAddress,
  updateLicenseeAddress,
  getLicenseeClubs,
  updateUserDetail,
  createLicenseeStudioInfo,
  updateLicenseeStudioInfo,
  getDefaultStudioInfo,
  getLicenseeStudioInfo,
  convertTrainingVideo,
  pushVideoCommand,
  pushScoreCard,
  pushTrainingVideo,
  getComments,
  createComment,
  updateComment,
  deleteComment,
  updateUserPasswordByAdmin,
  getPublicDocuments,
  updateMembership,
  uploadTeaserVideo,
  getDocuments,
  getDocumentsPublic,
  getSharedDocuments,
  getUserDocuments,
  getDocument,
  getDocumentPublic,
  getDocumentByVersion,
  createDocument,
  updateDocument,
  deleteDocument,
  getAcademyProducts,
  uploadDocumentFile,
  batchUpdateDocumentPath,
  moveDocument,
  copyDocument,
  createDocComment,
  updateDocComment,
  deleteDocComment,
  getDocumentPermissions,
  createDocumentPermissions,
  deleteDocumentPermissions,
  createSurvey,
  uploadSurveyPdf,
  updateSurvey,
  submitSurvey,
  deleteSurvey,
  getSurveys,
  generateDownloadLink,
  generateFileDownloadLink,
  getSurvey,
  attachSurveyPdf,
  getUserSurveys,
  uploadUserImage,
  deleteUserImage,
  getUserImage,
  registerUser,
  activateUserAccount,
  requestPasswordReset,
  getIp,
  getBriefDocuments,
  getUserGroups,
  getGroups,
  getGroup,
  getGroupByName,
  uploadDocumentEditorFile,
  updateUserPassword,
  uploadVideo,
  getGroupUsers,
  createGroup,
  deleteGroup,
  deleteUsersFromGroup,
  addUsersToGroup,
  updateUserGroups,
  getProducts,
  getProduct,
  getProductMappings,
  addDocumentPermissions,
  createProductMappings,
  deleteProduct,
  deleteProductMappings,
  checkFolderSize,
  getProductPreview,
  updateProductPreview,
  createProductPreview,
  uploadProductFile,
  getProductFile,
  getVideo,
  getVideos,
  createVideo,
  updateVideo,
  deleteVideo,
  getProfile,
  getUserCompletedSurveys,
  updateProfile,
  updatePassword,
  getSubpage,
  updateSubpage,
  uploadPdfFile,
  createSubpage,
  getVideoLink,
  updateUserStatus,
};
