import { createRequestTypes, action } from "../utils/actions";
import { REQUEST, SUCCESS, FAILURE } from "../utils/constants";

export const GET_SURVEYS = createRequestTypes("GET_SURVEYS");
export const GET_SURVEY = createRequestTypes("GET_SURVEY");
export const GET_USER_SURVEYS = createRequestTypes("GET_USER_SURVEYS");
export const CREATE_SURVEY = createRequestTypes("CREATE_SURVEY");
export const COMPLETE_SURVEY = createRequestTypes("COMPLETE_SURVEY");
export const GET_USER_COMPLETED_SURVEYS = createRequestTypes(
  "GET_USER_COMPLETED_SURVEYS"
);
export const UPDATE_SURVEY = createRequestTypes("UPDATE_SURVEY");
export const SUBMIT_SURVEY = createRequestTypes("SUBMIT_SURVEY");
export const DELETE_SURVEY = createRequestTypes("DELETE_SURVEY");
export const GET_SURVEY_BY_VERSION = createRequestTypes(
  "GET_SURVEY_BY_VERSION"
);

export const getSurveys = {
  request: () => action(GET_SURVEYS[REQUEST]),
  success: (payload) => action(GET_SURVEYS[SUCCESS], { payload }),
  failure: (payload) => action(GET_SURVEYS[FAILURE], { payload }),
};

export const getSurvey = {
  request: (id) => action(GET_SURVEY[REQUEST], { id }),
  success: (payload) => action(GET_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(GET_SURVEY[FAILURE], { payload }),
};

export const getUserSurveys = {
  request: (id) => action(GET_USER_SURVEYS[REQUEST], { id }),
  success: (payload) => action(GET_USER_SURVEYS[SUCCESS], { payload }),
  failure: (payload) => action(GET_USER_SURVEYS[FAILURE], { payload }),
};

export const getUserCompletedSurveys = {
  request: (id) => action(GET_USER_COMPLETED_SURVEYS[REQUEST], { id }),
  success: (payload) =>
    action(GET_USER_COMPLETED_SURVEYS[SUCCESS], { payload }),
  failure: (payload) =>
    action(GET_USER_COMPLETED_SURVEYS[FAILURE], { payload }),
};

export const getSurveyByVersion = {
  request: (data) => action(GET_SURVEY_BY_VERSION[REQUEST], { data }),
  success: (payload) => action(GET_SURVEY_BY_VERSION[SUCCESS], { payload }),
  failure: (payload) => action(GET_SURVEY_BY_VERSION[FAILURE], { payload }),
};

export const createSurvey = {
  request: (data) => action(CREATE_SURVEY[REQUEST], { data }),
  success: (payload) => action(CREATE_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_SURVEY[FAILURE], { payload }),
};

export const completeSurvey = {
  request: (data) => action(COMPLETE_SURVEY[REQUEST], { data }),
  success: (payload) => action(COMPLETE_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(COMPLETE_SURVEY[FAILURE], { payload }),
};

export const updateSurvey = {
  request: (id, data) => action(UPDATE_SURVEY[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_SURVEY[FAILURE], { payload }),
};

export const submitSurvey = {
  request: (id, data) => action(SUBMIT_SURVEY[REQUEST], { data }),
  success: (payload) => action(SUBMIT_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(SUBMIT_SURVEY[FAILURE], { payload }),
};

export const deleteSurvey = {
  request: (id) => action(DELETE_SURVEY[REQUEST], { id }),
  success: (payload) => action(DELETE_SURVEY[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_SURVEY[FAILURE], { payload }),
};
