import * as ActionTypes from "actions/Surveys";
import * as AuthActionTypes from "actions/Auth";

const initialState = {
  surveys: [],
  survey: {},
  userCompletedSurveys: [],
  userSurveys: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.GET_USER_SURVEYS.SUCCESS:
      return {
        ...state,
        userSurveys: action.payload,
      };
    case ActionTypes.GET_USER_COMPLETED_SURVEYS.SUCCESS:
      return {
        ...state,
        userCompletedSurveys: action.payload,
      };
    case ActionTypes.GET_SURVEYS.SUCCESS:
      return {
        ...state,
        surveys: action.payload,
      };
    case ActionTypes.GET_SURVEY.SUCCESS:
      return {
        ...state,
        survey: action.payload,
      };
    default:
      return state;
  }
}
