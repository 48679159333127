import { Route, Redirect, withRouter } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import Loader from 'react-loaders';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Notification from 'components/Notification';
import { checkAuth } from 'actions/Auth';
import { getSurveys } from 'actions/Surveys';
import { getDocuments, getBriefDocuments, getSharedDocuments, getPublicDocuments } from 'actions/Documents';
import { getFilteredUsers } from 'actions/User';
import { AdminRouts, UnauthRouts, UserRouts } from './Routs';
import AppHeader from '../AppHeader';
import AppSidebar from '../AppSidebar';
import { ToastContainer } from 'react-toastify';
import Loading from 'components/Loading';
import AppFooter from '../AppFooter';
import { getProfile } from '../../actions/Profile';
import { useKeycloak } from '@react-keycloak/web';
import { ROUTES } from '../../utils/constants';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const RoutesWithAuth = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();
  const userContentPattern = new RegExp(ROUTES.WIKI_USERCONTENT_1.replace(':id', '.*'), 'g');
  const [isIFrame, setIsIFrame] = useState(false);

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600).catch(() => keycloak.clearToken());
    }

    props.checkAuth();
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => { };
    };
  }, [keycloak, initialized]);

  useEffect(() => {
    if (props.userRole === 'user') {
      props.getProfile();
    }
  }, [props.userRole]);

  useEffect(() => {
    setIsIFrame(userContentPattern.test(location.pathname) || location.pathname === ROUTES.WIKI_SANITAS);
  }, [location.pathname]);

  if (!keycloak.authenticated) return null;

  if (!props.userRole)
    return (
      <>
        <AppHeader noTabs />
        <ReactCSSTransitionGroup component='div' transitionName='TabsAnimation' transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
          <div className={props.userRole === 'admin' ? 'd-container' : ''}>
            <div className={props.userRole === 'admin' ? 'd-content d-content-padding' : ''}>
              <div className='app-main__outer'>
                <div className='app-main__inner app-layout-main'>
                  <div>
                    <Suspense
                      fallback={
                        <div className='loader-container'>
                          <div className='loader-container-inner'>
                            <div className='text-center'>
                              <Loader type='ball-grid-beat' />
                            </div>
                            <h6 className='mt-3'>Keine Berechtigung auf diese Seite</h6>
                          </div>
                        </div>
                      }
                    >
                      {UnauthRouts}
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AppFooter />
        </ReactCSSTransitionGroup>
      </>
    );

  return (
    <>
      <ReactCSSTransitionGroup component='div' transitionName='TabsAnimation' transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className='d-container'>
          {props.userRole === 'admin' && <div className='d-sidebar-base'></div>}
          {props.userRole === 'admin' && <AppSidebar darkTheme={props.darkTheme} />}
          <div style={{ width: '100%' }} className={props.userRole === 'admin' ? 'd-content d-content-padding' : ''}>
            {!isIFrame && <AppHeader noTabs />}
            <div className='app-main__outer'>
              <div className='app-main__inner '>
                <Suspense
                  fallback={
                    <div className='loader-container'>
                      <div className='loader-container-inner'>
                        <div className='text-center'>
                          <Loader type='ball-grid-beat' />
                        </div>
                        <p className='mt-3'>Loading...</p>
                      </div>
                    </div>
                  }
                >
                  {props.userRole === 'admin' && AdminRouts}
                  {props.userRole === 'user' && UserRouts}
                </Suspense>
                {props.userRole === 'user' && (
                  <>
                    <Route exact path={ROUTES.HOME} render={() => <Redirect to={ROUTES.EXPERT_KNOWLEDGE} />} />
                  </>
                )}
                {props.userRole === 'admin' && (
                  <>
                    <Route exact path={ROUTES.HOME} render={() => <Redirect to={ROUTES.PAGES} />} />
                    <Route exact path={ROUTES.WIKI_SANITAS} render={() => <Redirect to={ROUTES.PAGES} />} />
                  </>
                )}
              </div>
            </div>
          </div>{' '}
          <ToastContainer />
        </div>
        {!isIFrame && <AppFooter />}
      </ReactCSSTransitionGroup>
      <Notification />
      {<Loading loading={props.loading} progress={props.progress} />}
    </>
  );
};

const mapStateToProps = ({ UserRoles, Documents, Loading, ThemeOptions }) => ({
  userRole: UserRoles.userRole,
  briefDocuments: Documents.briefDocuments,
  loading: Loading.loading,
  progress: Loading.progress,
  darkTheme: ThemeOptions.darkTheme,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(getFilteredUsers.request()),
  getSurveys: () => dispatch(getSurveys.request()),
  getProfile: () => dispatch(getProfile.request()),
  getDocuments: () => dispatch(getDocuments.request()),
  getBriefDocuments: () => dispatch(getBriefDocuments.request()),
  getSharedDocuments: () => dispatch(getSharedDocuments.request()),
  getPublicDocuments: () => dispatch(getPublicDocuments.request()),
  checkAuth: () => dispatch(checkAuth.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RoutesWithAuth));
