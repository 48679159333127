export default function reducer(
  state = {
    error: null,
  },
  action
) {
  if (action.type.includes("FAILURE")) return { error: action.payload };
  if (action.type === "CLEAR_NOTIFICATION_ERROR") return { error: null };
  return state;
}
