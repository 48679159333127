import { takeEvery } from "redux-saga/effects";
import { apiRequest } from "./index";
import api from "utils/api";
import { Slide, toast } from "react-toastify";
import * as surveyActions from "actions/Surveys";
import history from "utils/history";
import { get } from "lodash";

const getSurveys = apiRequest.bind(
  null,
  surveyActions.getSurveys,
  api.getSurveys
);
const getSurvey = apiRequest.bind(null, surveyActions.getSurvey, api.getSurvey);
const getUserSurveys = apiRequest.bind(
  null,
  surveyActions.getUserSurveys,
  api.getUserSurveys
);
const getUserCompletedSurveys = apiRequest.bind(
  null,
  surveyActions.getUserCompletedSurveys,
  api.getUserCompletedSurveys
);
const createSurvey = apiRequest.bind(
  null,
  surveyActions.createSurvey,
  api.createSurvey
);
const updateSurvey = apiRequest.bind(
  null,
  surveyActions.updateSurvey,
  api.updateSurvey
);
const submitSurvey = apiRequest.bind(
  null,
  surveyActions.submitSurvey,
  api.submitSurvey
);
const completeSurvey = apiRequest.bind(
  null,
  surveyActions.completeSurvey,
  api.completeSurvey
);
const deleteSurvey = apiRequest.bind(
  null,
  surveyActions.deleteSurvey,
  api.deleteSurvey
);
const getSurveyByVersion = apiRequest.bind(
  null,
  surveyActions.getSurveyByVersion,
  api.getSurveyByVersion
);

function* handleChangeSurvey(action) {
  if (get(action.payload, "options") === "redirect") {
    return yield history.push("/surveys");
  } else {
    yield history.push(`/update-survey/${action.payload.id}`);

    yield toast("Gespeichert", {
      transition: Slide,
      closeButton: true,
      closeOnClick: true,
      autoClose: true,
      position: "bottom-center",
      type: "success",
    });
    const arg = { id: action.payload.id };
    yield apiRequest.apply(null, [surveyActions.getSurvey, api.getSurvey, arg]);
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetSurveysRequest() {
  yield takeEvery(surveyActions.GET_SURVEYS.REQUEST, getSurveys);
}

export function* watchGetSurveyRequest() {
  yield takeEvery(surveyActions.GET_SURVEY.REQUEST, getSurvey);
}

export function* watchGetUserSurveysRequest() {
  yield takeEvery(surveyActions.GET_USER_SURVEYS.REQUEST, getUserSurveys);
}

export function* watchCreateSurveyRequest() {
  yield takeEvery(surveyActions.CREATE_SURVEY.REQUEST, createSurvey);
}

export function* watchCompleteSurveyRequest() {
  yield takeEvery(surveyActions.CREATE_SURVEY.REQUEST, completeSurvey);
}

export function* watchUpdateSurveyRequest() {
  yield takeEvery(surveyActions.UPDATE_SURVEY.REQUEST, updateSurvey);
}

export function* watchDeleteSurveyRequest() {
  yield takeEvery(surveyActions.DELETE_SURVEY.REQUEST, deleteSurvey);
}

export function* watchDeleteSurveySuccess() {
  yield takeEvery(surveyActions.DELETE_SURVEY.SUCCESS, getSurveys);
}

export function* watchSubmitSurveyRequest() {
  yield takeEvery(surveyActions.SUBMIT_SURVEY.REQUEST, submitSurvey);
}

export function* watchCreateSurveySuccess() {
  yield takeEvery(surveyActions.CREATE_SURVEY.SUCCESS, handleChangeSurvey);
}

export function* watchUpdateSurveySuccess() {
  yield takeEvery(surveyActions.UPDATE_SURVEY.SUCCESS, handleChangeSurvey);
}

// export function* watchCompleteSurveySuccess() {
//   yield takeEvery(surveyActions.COMPLETE_SURVEY.SUCCESS, handleChangeSurvey)
// }

export function* watchGetDocumentByVersionRequest() {
  yield takeEvery(
    surveyActions.GET_SURVEY_BY_VERSION.REQUEST,
    getSurveyByVersion
  );
}

export function* watchGetUserCompletedSurveysRequest() {
  yield takeEvery(
    surveyActions.GET_USER_COMPLETED_SURVEYS.REQUEST,
    getUserCompletedSurveys
  );
}
