import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class HorizontalNavWrapper extends Component {
  state = {
    openMenu: undefined,
    openInnerMenu: undefined,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        openMenu: undefined,
        openInnerMenu: undefined,
      });
    }
  }

  handleMenuOpen = (id, state = true) => {
    if (id === this.state.openMenu) {
      this.setState({
        openMenu: undefined,
      });
      return;
    }

    this.setState({
      openMenu: id,
    });
  };

  handleMenuClose = (e) => {
    this.setState({
      openMenu: undefined,
    });
  };

  handleInnerMenuOpen = (id, state = true) => {
    if (id === this.state.openInnerMenu) {
      this.setState({
        openInnerMenu: undefined,
      });
      return;
    }
    this.setState({
      openInnerMenu: id,
    });
  };

  handleInnerMenuClose = () => {
    this.setState({
      openInnerMenu: undefined,
    });
  };

  renderMenuItem = (content, navItemName) => {
    const currentRoot = window.location.pathname.split('/')[1];

    const isMobile = window.innerWidth <= 991;
    if (!content) return null;

    return (
      <div
        className='navigation__item navigation__item--top'
        onMouseEnter={() => (!isMobile ? this.handleMenuOpen(content.id) : null)}
        onMouseLeave={() => (!isMobile ? this.handleMenuClose() : null)}
      >
        <Link to={`/${navItemName.toLowerCase()}`} onClick={this.props.closeMenu} className='navigation__item-link' style={currentRoot === navItemName.toLowerCase() ? { color: '#e95095' } : null}>
          {navItemName}
        </Link>
        <span className='navigation__arrow'>
          <FontAwesomeIcon icon={this.state.openMenu === content.id ? faAngleUp : faAngleDown} onClick={() => this.handleMenuOpen(content.id)} />
        </span>

        {this.state.openMenu === content.id && (
          <div className='navigation__dropdown'>
            {content.children.length &&
              content.children.map((el, index) => {
                if (el.type === 'file') return '';
                return (
                  <div
                    key={index}
                    className='navigation__item'
                    onMouseEnter={() => (!isMobile ? this.handleInnerMenuOpen(el.id) : null)}
                    onMouseLeave={() => (!isMobile ? this.handleInnerMenuClose() : null)}
                  >
                    <Link className='navigation__dropdown-item' onClick={this.props.closeMenu} to={`/${content.name.toLowerCase()}/${el.name}`}>
                      {el.name}
                    </Link>

                    {isMobile && el.children.length ? (
                      <span className='navigation__arrow'>
                        <FontAwesomeIcon icon={this.state.openInnerMenu === el.id ? faAngleUp : faAngleDown} className='navigation__arrow' onClick={() => this.handleInnerMenuOpen(el.id)} />
                      </span>
                    ) : (
                      ''
                    )}

                    {this.state.openInnerMenu === el.id && (
                      <div className='navigation__dropdown navigation__dropdown--inner'>
                        {el.children.length
                          ? el.children.map((innerEl, innerIndex) => {
                              if (innerEl.type === 'file') return '';
                              return (
                                <Link className='navigation__dropdown-item' onClick={this.props.closeMenu} to={`/${content.name.toLowerCase()}/${el.name}/${innerEl.name}`} key={innerIndex}>
                                  {innerEl.name}
                                </Link>
                              );
                            })
                          : ''}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  };

  renderContacts = (navItemName) => {
    return (
      <div className='navigation__item navigation__item--top' onMouseEnter={() => this.handleMenuOpen('contacts')} onMouseLeave={() => this.handleMenuClose()}>
        <a href='https://forumviasanitas.org/kontakt/' className='navigation__item-link'>
          {navItemName}
        </a>
        {this.state.openMenu === 'contacts' && (
          <div className='navigation__dropdown'>
            <div className='navigation__item'>
              <a href='https://forumviasanitas.org/forum/' className='navigation__dropdown-item'>
                Über uns
              </a>
              <a href='https://forumviasanitas.org/kontakt/' className='navigation__dropdown-item'>
                So erreichen Sie uns!
              </a>
              <a href='https://forumviasanitas.org/newsletter/' className='navigation__dropdown-item'>
                Newsletter abonnieren
              </a>
              <a href='https://forumviasanitas.org/kontakt/impressum/' className='navigation__dropdown-item'>
                Impressum
              </a>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { documents } = this.props;

    if (!documents) return null;

    const isMobile = window.innerWidth <= 991;

    return (
      <>
        {(this.props.open || !isMobile) && (
          <ReactCSSTransitionGroup
            component='div'
            transitionName='fade'
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={true}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            <div className='horizontal-nav'>
              <div className='navigation__item navigation__item--top'>
                <a href='https://forumviasanitas.org/' className='navigation__item-link'>
                  Home
                </a>
              </div>
              {this.renderMenuItem(documents.wikisanitas, 'Wikisanitas')}
              {this.renderMenuItem(documents.campus, 'Campus')}
              {this.renderMenuItem(documents.community, 'Community')}
              {this.renderContacts('Kontakt')}
            </div>
          </ReactCSSTransitionGroup>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ UserRoles, Documents }) => ({
  userRole: UserRoles.userRole,
  documents: !UserRoles.userRole ? Documents.documents : Documents.sharedDocuments,
});

export default connect(mapStateToProps, null)(withRouter(HorizontalNavWrapper));
