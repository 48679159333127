import React, { useState } from "react";
import logo from "../../../assets/utils/images/logo-sanitas.png";
import Nav from "../../AppNav/HorizontalNavWrapper";
import { get } from "lodash";
import Button from "reactstrap/lib/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import line from "../../../assets/utils/images/selector.svg";
import MobileMenuOverlay from "./MobileMenuOverlay";
import { AdminNav } from "../../AppNav/NavItems";

const getNav = (userRole) => {
  if (userRole === "admin") return AdminNav;
};

const pagesWithFixedHeader = [
  "/update-file",
  "/update-survey",
  "/create-survey",
  "/create-file",
  "/view-file",
  "/view-user",
  "/edit-user",
  "/create-user",
  "/create-user",
  "/create-video",
  "/edit-video",
  "/view-video",
];

const checkHeaderFixed = (history) => {
  const path = get(history, "location.pathname");
  return pagesWithFixedHeader.reduce((acc, el) => {
    if (acc) return acc;
    if (path.includes(el)) return true;
    return acc;
  }, false);
};

const AdminHeader = ({ userRole, history, logout, user, noTabs }) => {
  const [menuOpen, toggleMenu] = useState(false);

  const isHeaderFixed = checkHeaderFixed(history);

  const handleLogout = () => {
    logout();
  };

  const renderLogout = () => (
    <>
      <Button className="d-logout-text" onClick={handleLogout}>
        Logout
      </Button>
    </>
  );


  return (
    <>
      <div
        className={`d-header
      ${isHeaderFixed && userRole === "admin" ? "d-header-fixed" : ""}
      `}
      >
        <div className="d-header-top">
          {userRole !== "admin" && (
            <img className="d-header-logo" alt="FVS" src={logo} />
          )}
          {(userRole === "user" || !userRole) && (
            <Nav open={menuOpen} closeMenu={() => toggleMenu(false)} />
          )}
          {userRole === "admin" && (
            <div className="d-header-title">
              Welcome {get(user, "preferred_username")}!
            </div>
          )}
          <div style={{ display: "flex" }}>
            <div className="d-top-header-content-right">
              {userRole === "user" && (
                <Button
                  onClick={() => history.push("/academy")}
                  style={{
                    background: "#5E0B3A",
                    color: "#fff",
                    marginRight: 10,
                  }}
                  className="d-logout-text"
                >
                  Apo Academy
                </Button>
              )}
              {userRole && renderLogout()}
            </div>
            {
              <div
                className="d-header-menu-mobile"
                onClick={() => toggleMenu(!menuOpen)}
              >
                <FontAwesomeIcon icon={faBars} />
              </div>
            }
          </div>
        </div>
        {!noTabs && (
          <div className="d-header-bottom">
            <div className="d-tab d-tab-active">
              MY BODY CLUB <img alt="line" src={line} />
            </div>
            <div className="d-tab">OVERVIEW</div>
          </div>
        )}
      </div>
      {userRole === "admin" && (
        <MobileMenuOverlay
          open={menuOpen}
          closeMenu={() => toggleMenu(false)}
          nav={getNav(userRole)}
        />
      )}
    </>
  );
};

export default AdminHeader;
