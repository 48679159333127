import React from 'react';
import { withRouter } from 'react-router-dom';
import AdminHeader from './Components/AdminHeader';

import { logout } from 'actions/Auth';
import { connect } from 'react-redux';
import UserHeader from './UserHeader';

const Component = ({ logout, noTabs = false, user, userRole, history }) => {
  return (
    <>
      {userRole === 'admin' && <AdminHeader logout={logout} userRole={userRole} history={history} user={user} noTabs={noTabs} />}
      {userRole !== 'admin' && <UserHeader logout={logout} history={history} />}
    </>
  );
};

const mapStateToProps = ({ UserRoles, Auth, ThemeOptions }) => ({
  userRole: UserRoles.userRole,
  user: Auth.user,
  darkTheme: ThemeOptions.darkTheme,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout.request()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
