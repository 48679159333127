import { put, takeEvery } from 'redux-saga/effects';
import { apiRequest } from './index';
import api from 'utils/api';
import * as documentActions from 'actions/Documents';
import history from 'utils/history';
import { get } from 'lodash';
import { Slide, toast } from 'react-toastify';
import Axios from 'axios';
import generateFileURL from '../utils/generateFileURL';

const getDocuments = apiRequest.bind(null, documentActions.getDocuments, api.getDocuments);
const getDocumentsPublic = apiRequest.bind(null, documentActions.getDocumentsPublic, api.getDocumentsPublic);
const getSubpage = apiRequest.bind(null, documentActions.getSubpage, api.getSubpage);
const createSubpage = apiRequest.bind(null, documentActions.createSubpage, api.createSubpage);
const updateSubpage = apiRequest.bind(null, documentActions.updateSubpage, api.updateSubpage);
const getBriefDocuments = apiRequest.bind(null, documentActions.getBriefDocuments, api.getBriefDocuments);
const getPublicDocuments = apiRequest.bind(null, documentActions.getPublicDocuments, api.getPublicDocuments);
const getUserDocuments = apiRequest.bind(null, documentActions.getUserDocuments, api.getUserDocuments);
const getSharedDocuments = apiRequest.bind(null, documentActions.getSharedDocuments, api.getSharedDocuments);
const getDocument = apiRequest.bind(null, documentActions.getDocument, api.getDocument);
const getDocumentPublic = apiRequest.bind(null, documentActions.getDocumentPublic, api.getDocumentPublic);
const getDocumentByVersion = apiRequest.bind(null, documentActions.getDocumentByVersion, api.getDocumentByVersion);
const createDocument = apiRequest.bind(null, documentActions.createDocument, api.createDocument);
const updateDocument = apiRequest.bind(null, documentActions.updateDocument, api.updateDocument);
// const createDocumentPermissions = apiRequest.bind(null, documentActions.createDocumentPermissions, api.createDocumentPermissions);
// const deleteDocumentPermissions = apiRequest.bind(null, documentActions.deleteDocumentPermissions, api.deleteDocumentPermissions);
const getDocumentPermissions = apiRequest.bind(null, documentActions.getDocumentPermissions, api.getDocumentPermissions);

function* getVideoLink({ id }) {
  const { response } = yield api.getVideoLink(id);
  yield put({ type: documentActions.GET_VIDEO_LINK.SUCCESS, payload: { link: response, key: id } });
}

function* getDocumentPdfFile({ data }) {
  const newBrowserTab = window.open('', '_blank');

  yield Axios.get(data, {
    responseType: 'arraybuffer',
  }).then((response) => {
    newBrowserTab.location.href = generateFileURL(response);
  });
  yield put({ type: documentActions.GET_DOCUMENT_PDF_FILE.SUCCESS });
}

/**
 * function to download a pdf on button click (used for nutrition tips)
 * @param data: pdf link
 * @param pdfFileName: download name of pdf file
 */
function* downloadDocumentPdfFile({ data, pdfFileName }) {
  yield Axios.get(data, {
    responseType: 'arraybuffer',
  }).then((response) => {
    const file = generateFileURL(response);

    //create a tag
    const link = document.createElement('a');

    //convert url to pdf into blob and create an object url out of it
    link.href = file;
    //set file name for download
    link.setAttribute('download', pdfFileName);

    // append to html link element page
    document.body.appendChild(link);

    // start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });

  yield put({ type: documentActions.DOWNLOAD_DOCUMENT_PDF_FILE.SUCCESS });
}

function* deleteDocument({ id }) {
  yield api.deleteDocument(id);
  yield put({ type: documentActions.DELETE_DOCUMENT.SUCCESS, payload: { id } });
}

function* handleChangeDocument(action) {
  if (get(action.payload, 'options') === 'redirect') {
    return yield history.push('/pages');
  } else if (get(action.payload, 'options') === 'edit') {
    return yield history.push('/update-file/' + action.payload.id);
  } else {
    yield toast('Saved', {
      transition: Slide,
      closeButton: true,
      autoClose: 3000,
      position: 'bottom-center',
      type: 'success',
    });
    const arg = { id: action.payload.id };
    yield apiRequest.apply(null, [documentActions.getDocument, api.getDocument, arg]);
  }
}

function* handleCreateSubpageSuccess(action) {
  if (get(action.payload, 'options') === 'redirect') {
    return yield history.push('/pages');
  } else {
    yield history.push(`/update-file/${action.payload.parentId}`);

    yield toast('Saved', {
      transition: Slide,
      closeButton: true,
      autoClose: false,
      position: 'bottom-center',
      type: 'success',
    });
    const arg = { id: action.payload.parentId };
    yield apiRequest.apply(null, [documentActions.getDocument, api.getDocument, arg]);
  }
}

function* handleGetDocumentFailure(action) {
  if (action.payload.status === 409) {
    return yield history.push('/', { targetURL: window.location.pathname });
  }
}

function* handleUpdateSubpageSuccess(action) {
  if (get(action.payload, 'options') === 'redirect') {
    return yield history.push('/pages');
  } else {
    yield toast('Saved', {
      transition: Slide,
      closeButton: true,
      autoClose: 3000,
      position: 'bottom-center',
      type: 'success',
    });
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetDocumentsRequest() {
  yield takeEvery(documentActions.GET_DOCUMENTS.REQUEST, getDocuments);
}

export function* watchGetDocumentsPublicRequest() {
  yield takeEvery(documentActions.GET_DOCUMENTS_PUBLIC.REQUEST, getDocumentsPublic);
}

export function* watchGetBriefDocumentsRequest() {
  yield takeEvery(documentActions.GET_BRIEF_DOCUMENTS.REQUEST, getBriefDocuments);
}

export function* watchgetPublicDocumentsRequest() {
  yield takeEvery(documentActions.GET_PUBLIC_DOCUMENTS.REQUEST, getPublicDocuments);
}

export function* watchGetSharedDocumentsRequest() {
  yield takeEvery(documentActions.GET_SHARED_DOCUMENTS.REQUEST, getSharedDocuments);
}

export function* watchGetUserDocumentsRequest() {
  yield takeEvery(documentActions.GET_USER_DOCUMENTS.REQUEST, getUserDocuments);
}

export function* watchGetDocumentRequest() {
  yield takeEvery(documentActions.GET_DOCUMENT.REQUEST, getDocument);
}

export function* watchGetDocumentPublicRequest() {
  yield takeEvery(documentActions.GET_DOCUMENT_PUBLIC.REQUEST, getDocumentPublic);
}

export function* watchGetDocumentFailure() {
  yield takeEvery(documentActions.GET_DOCUMENT.FAILURE, handleGetDocumentFailure);
}

export function* watchGetSubpageRequest() {
  yield takeEvery(documentActions.GET_SUBPAGE.REQUEST, getSubpage);
}

export function* watchGetDocumentByVersionRequest() {
  yield takeEvery(documentActions.GET_DOCUMENT_BY_VERSION.REQUEST, getDocumentByVersion);
}

export function* watchCreateDocumentRequest() {
  yield takeEvery(documentActions.CREATE_DOCUMENT.REQUEST, createDocument);
}

export function* watchUpdateDocumentRequest() {
  yield takeEvery(documentActions.UPDATE_DOCUMENT.REQUEST, updateDocument);
}

export function* watchDeleteDocumentRequest() {
  yield takeEvery(documentActions.DELETE_DOCUMENT.REQUEST, deleteDocument);
}

export function* watchCreateDocumentSuccess() {
  yield takeEvery(documentActions.CREATE_DOCUMENT.SUCCESS, handleChangeDocument);
}

export function* watchUpdateDocumentSuccess() {
  yield takeEvery(documentActions.UPDATE_DOCUMENT.SUCCESS, handleChangeDocument);
}

export function* watchGetDocumentPermissionsRequest() {
  yield takeEvery(documentActions.GET_DOCUMENT_PERMISSIONS.REQUEST, getDocumentPermissions);
}

export function* watchCreateSubpageRequest() {
  yield takeEvery(documentActions.CREATE_SUBPAGE.REQUEST, createSubpage);
}

export function* watchCreateSubpageSuccess() {
  yield takeEvery(documentActions.CREATE_SUBPAGE.SUCCESS, handleCreateSubpageSuccess);
}

export function* watchUpdateSubpageRequest() {
  yield takeEvery(documentActions.UPDATE_SUBPAGE.REQUEST, updateSubpage);
}

export function* watchGetVideoLinkRequest() {
  yield takeEvery(documentActions.GET_VIDEO_LINK.REQUEST, getVideoLink);
}

export function* watchGetDocumentPdfFileRequest() {
  yield takeEvery(documentActions.GET_DOCUMENT_PDF_FILE.REQUEST, getDocumentPdfFile);
}

export function* watchDownloadDocumentPdfFileRequest() {
  yield takeEvery(documentActions.DOWNLOAD_DOCUMENT_PDF_FILE.REQUEST, downloadDocumentPdfFile);
}

export function* watchUpdateSubpageSuccess() {
  yield takeEvery(documentActions.UPDATE_SUBPAGE.SUCCESS, handleUpdateSubpageSuccess);
}
