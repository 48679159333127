import { createRequestTypes, action } from "../utils/actions";
import { REQUEST, SUCCESS, FAILURE } from "../utils/constants";

export const CREATE_LICENSEE_KEYCLOAK = createRequestTypes(
  "CREATE_LICENSEE_KEYCLOAK"
);
export const CREATE_LICENSEE = createRequestTypes("CREATE_LICENSEE");

export const GET_LICENSEES = createRequestTypes("GET_LICENSEES");

export const GET_LICENSEE = createRequestTypes("GET_LICENSEE");
export const UPDATE_LICENSEE = createRequestTypes("UPDATE_LICENSEE");
export const DELETE_LICENSEE = createRequestTypes("DELETE_LICENSEE");
export const SET_LICENSEE_PAGE = "SET_LICENSEE_PAGE";
export const SET_LICENSEE_TABLE_FILTER = "SET_ADMIN_TABLE_FILTER";
export const SET_LICENSEE_PAGE_SIZE = "SET_LICENSEE_PAGE_SIZE";

export const setLicenseePageSize = (payload) => ({
  type: SET_LICENSEE_PAGE_SIZE,
  payload,
});

export const setLicenseeTableFilter = (payload) => ({
  type: SET_LICENSEE_TABLE_FILTER,
  payload,
});

export const createLicenseeKeycloak = {
  request: (data) => action(CREATE_LICENSEE_KEYCLOAK[REQUEST], { data }),
  success: (payload) => action(CREATE_LICENSEE_KEYCLOAK[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_LICENSEE_KEYCLOAK[FAILURE], { payload }),
};

export const createLicensee = {
  request: (data) => action(CREATE_LICENSEE[REQUEST], { data }),
  success: (payload) => action(CREATE_LICENSEE[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_LICENSEE[FAILURE], { payload }),
};

export const getLicensees = {
  request: () => action(GET_LICENSEES[REQUEST]),
  success: (payload) => action(GET_LICENSEES[SUCCESS], { payload }),
  failure: (payload) => action(GET_LICENSEES[FAILURE], { payload }),
};

export const getLicensee = {
  request: (id) => action(GET_LICENSEE[REQUEST], { id }),
  success: (payload) => action(GET_LICENSEE[SUCCESS], { payload }),
  failure: (payload) => action(GET_LICENSEE[FAILURE], { payload }),
};

export const updateLicensee = {
  request: (id, data) => action(UPDATE_LICENSEE[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_LICENSEE[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_LICENSEE[FAILURE], { payload }),
};

export const deleteLicensee = {
  request: (id) => action(DELETE_LICENSEE[REQUEST], { id }),
  success: (payload) => action(DELETE_LICENSEE[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_LICENSEE[FAILURE], { payload }),
};
