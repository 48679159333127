import * as ActionTypes from 'actions/Documents';
import * as AuthActionTypes from 'actions/Auth';
import { get } from 'lodash';

const initialState = {
  documents: {
    list: [],
    totalPages: 1,
    totalElements: 0,
  },
  tableFilter: [],
  sharedDocuments: {},
  userDocuments: [],
  productsFilter: {
    type: null,
    category: null,
    contains: '',
  },
  videoLinks: {},
  briefDocuments: [],
  document: null,
  publicDocument: {
    folders: [], // subfolders for curr open folder
    files: [], // content blocks to display
    breadcrumbs: [], // navItems
    // format for breadcrumb:
    /* {
      ref: <publicDocument>, // ref to nav item
      text: <string> // text to display for breadcrumb
    } */
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_DOCUMENTS_FILTER:
      return { ...state, productsFilter: { ...state.productsFilter, category: undefined, contains: '' } };
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.SET_PUBLIC_DOCUMENT:
      return {
        ...state,
        publicDocument: action.payload,
      };
    case ActionTypes.SET_PRODUCTS_FILTER:
      return {
        ...state,
        productsFilter: action.payload,
      };
    case ActionTypes.SET_DOCUMENTS_FILTER:
      return {
        ...state,
        tableFilter: action.payload,
      };
    case ActionTypes.GET_SHARED_DOCUMENTS.SUCCESS:
      return {
        ...state,
        sharedDocuments: action.payload,
      };
    case ActionTypes.GET_BRIEF_DOCUMENTS.SUCCESS:
      return {
        ...state,
        briefDocuments: action.payload,
      };
    case ActionTypes.GET_DOCUMENTS.SUCCESS:
    case ActionTypes.GET_DOCUMENTS_PUBLIC.SUCCESS:
      return {
        ...state,
        documents: action.payload,
        publicWiki: get(action.payload, 'publicWiki'),
      };
    case ActionTypes.GET_PUBLIC_DOCUMENTS.SUCCESS:
      return {
        ...state,
        documents: action.payload,
        publicWiki: get(action.payload, 'publicWiki'),
      };
    case ActionTypes.GET_USER_DOCUMENTS.SUCCESS:
      return {
        ...state,
        userDocuments: action.payload,
      };
    case ActionTypes.GET_DOCUMENT.SUCCESS:
    case ActionTypes.GET_DOCUMENT_PUBLIC.SUCCESS:
    case ActionTypes.GET_DOCUMENT_BY_VERSION.SUCCESS:
      return {
        ...state,
        document: action.payload,
      };
    case ActionTypes.GET_SUBPAGE.SUCCESS:
      return {
        ...state,
        subpage: action.payload,
      };
    case ActionTypes.GET_VIDEO_LINK.SUCCESS:
      return {
        ...state,
        videoLinks: {
          ...state.videoLinks,
          [action.payload.key]: action.payload.link,
        },
      };
    case ActionTypes.DELETE_DOCUMENT.SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          list: state.documents.list.filter(({ id }) => id !== action.payload.id),
        },
      };
    default:
      return state;
  }
}
