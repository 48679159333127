export default function () {
  // eslint-disable-next-line
  String.prototype.toHHMMSS = function () {
    var sec_total = parseFloat(this);
    var sec_num = parseInt(this, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    var msec = ((sec_total - sec_num) * 1000).toFixed(0);
    if (msec < 10) msec = "00" + msec;
    else if (msec < 100) msec = "0" + msec;

    return hours + ":" + minutes + ":" + seconds + "." + msec;
  };
}

export const toSeconds = (hmsm) => {
  var hms = hmsm.split(".");
  var a = hms[0].split(":");
  var msec = hms[1] || "0";
  var sec = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  return parseFloat(`${sec}.${msec}`);
};

export const toMinutes = (hms) => {
  return Math.floor(toSeconds(hms) / 60);
};
