import { put, takeEvery } from 'redux-saga/effects';
import { apiRequest } from './index';
import history from 'utils/history';
import api from 'utils/api';
import * as authActions from 'actions/Auth';
import * as licenseeActions from 'actions/User';
import { get } from 'lodash';
import jwtDecode from 'jwt-decode';
import keycloak from '../utils/keycloak';
import { checkTokenExpiration, checkRefreshTokenExpiration } from 'utils/token';
import { ROUTES } from '../utils/constants';

const refreshToken = apiRequest.bind(null, authActions.getNewToken, api.refreshToken);

const getUserRole = (decoded) =>
  get(decoded, 'realm_access.roles', []).reduce((acc, el) => {
    if (el === 'admin' || acc === 'admin') return 'admin';
    if (el === 'licensee' || acc === 'licensee') return 'licensee';
    if (el === 'user' || acc || 'user') return 'user';
    return acc;
  }, '');

function* getToken() {
  const token = checkTokenExpiration();
  if (token && token !== 'expired') {
    const decoded = jwtDecode(token);
    const userRole = getUserRole(decoded);
    yield put({ type: 'SET_USER_ROLE', payload: userRole });
    yield put({ type: authActions.GET_TOKEN.SUCCESS, payload: decoded });
  } else {
    if (token === 'expired') {
      const refreshToken = checkRefreshTokenExpiration();
      if (!refreshToken || refreshToken === 'expired') {
        yield put({
          type: authActions.GET_TOKEN.FAILURE,
          payload: { error: { message: 'Session expired' } },
        });
      } else {
        yield put({
          type: authActions.GET_NEW_TOKEN.REQUEST,
          data: refreshToken,
        });
      }
    }
  }
}

function* saveToken(action) {
  const { access_token, refresh_token, decoded, isRefresh } = action.payload;
  const userRole = getUserRole(decoded);
  window.localStorage.setItem('BC_TOKEN', access_token);
  window.localStorage.setItem('BC_REFRESH_TOKEN', refresh_token);

  if (action.payload.newTargetUrl) {
    yield put({ type: 'SET_USER_ROLE', payload: userRole });
    history.push(action.payload.newTargetUrl);
    return;
  }
  yield put({
    type: 'SET_USER_ROLE',
    payload: userRole,
    isRedirect: isRefresh ? false : true,
  });
  yield put({ type: authActions.GET_TOKEN.SUCCESS, payload: decoded });
}

function* removeToken() {
  window.localStorage.removeItem('BC_TOKEN');
  window.localStorage.removeItem('BC_REFRESH_TOKEN');
  yield put({ type: licenseeActions.USERS.SUCCESS, payload: [] }); // clear users
  yield put({ type: 'SET_USER_ROLE', payload: '' });
  keycloak.logout();
}

export function* handleInvalidToken() {
  yield put({ type: authActions.LOGOUT.REQUEST });
  yield put({
    type: authActions.GET_TOKEN.FAILURE,
    payload: { error: { message: 'Session expired' } },
  });
  history.push('/');
}

export function* handleSetUserRole(action) {
  if (!action.payload && !history.location.pathname.includes('register')) {
    return yield history.push('/');
  }
  if (action.isRedirect) {
    if (action.payload === 'admin') return yield history.push('/');
    if (action.payload === 'user') return yield history.push(ROUTES.MY_MODULES);
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetToken() {
  yield takeEvery(authActions.GET_TOKEN.REQUEST, getToken);
}

export function* watchLogout() {
  yield takeEvery(authActions.LOGOUT.REQUEST, removeToken);
}

export function* watchGetTokenFailure() {
  yield takeEvery(authActions.GET_TOKEN.FAILURE, removeToken);
}

export function* watchRefreshToken() {
  yield takeEvery(authActions.GET_NEW_TOKEN.REQUEST, refreshToken);
}

export function* watchRefreshTokenSuccess() {
  yield takeEvery(authActions.GET_NEW_TOKEN.SUCCESS, saveToken);
}

export function* watchRefreshTokenFailure() {
  yield takeEvery(authActions.GET_NEW_TOKEN.FAILURE, handleInvalidToken);
}

export function* watchSetUserRole() {
  yield takeEvery('SET_USER_ROLE', handleSetUserRole);
}
