import { Route, Switch, Redirect } from 'react-router-dom';
import React, { lazy } from 'react';
import { ROUTES } from '../../utils/constants';

const NotFoundPage = lazy(() => import('pages/NotFound'));
const UserProfile = lazy(() => import('pages/UserProfile'));
const PageList = lazy(() => import('pages/PageList'));
const VideosList = lazy(() => import('pages/VideosList'));
const VideoDetails = lazy(() => import('pages/VideoDetails'));
const UserContentPage = lazy(() => import('pages/UserContentPage'));
const UserContentPageFrame = lazy(() => import('../../pages/UserContentPage/UserContentPageFrame'));
const CreateVideo = lazy(() => import('pages/CreateVideo'));
const UserListPage = lazy(() => import('pages/UserList'));
const CreateUserPage = lazy(() => import('pages/CreateUser'));
const UserDetails = lazy(() => import('pages/UserDetails'));
const DocumentDetailsPage = lazy(() => import('pages/PageDetails'));
const CreateDocumentPage = lazy(() => import('pages/CreatePage'));
const SurveyDetailsPage = lazy(() => import('pages/SurveyDetails'));
const CreateSurveyPage = lazy(() => import('pages/CreateSurvey'));
const SurveysListPage = lazy(() => import('pages/SurveyList'));
//const UserSurvey = lazy(() => import("pages/UserSurvey"));
const GroupDetails = lazy(() => import('pages/GroupDetails'));
const GroupsList = lazy(() => import('pages/GroupsList'));
const ProductsList = lazy(() => import('pages/ProductsList'));
const ProductDetails = lazy(() => import('pages/ProductDetails'));
const UserProducts = lazy(() => import('pages/UserProducts'));
const ChangePermissions = lazy(() => import('pages/ChangePermissions'));
const UserResults = lazy(() => import('pages/UserProfile/UserSurveys/UserResults'));
// const ChangePassword = lazy(() => import('pages/UserProfile/ChangePassword/ChangePassword'));

export const AdminRouts = (
  <Switch>
    <Route path={ROUTES.CHANGE_PERMISSIONS} exact component={ChangePermissions} />
    <Route path={ROUTES.USERCONTENT_1} exact component={UserContentPage} />
    <Route path={ROUTES.USERCONTENT_2} exact component={UserContentPage} />
    <Route path={ROUTES.PAGES} component={PageList} />
    <Route path={ROUTES.USERS} component={UserListPage} />
    <Route path={ROUTES.VIDEOS} component={VideosList} />
    <Route path={ROUTES.CREATE_VIDEO} component={CreateVideo} />
    <Route path={ROUTES.VIEW_VIDEO} component={VideoDetails} />
    <Route path={ROUTES.EDIT_VIDEO} component={VideoDetails} />
    <Route path={ROUTES.VIEW_USER} component={UserDetails} />
    <Route path={ROUTES.CREATE_USER} component={CreateUserPage} />
    <Route path={ROUTES.EDIT_USER} component={UserDetails} />
    <Route path={ROUTES.SURVEYS} component={SurveysListPage} />
    <Route path={ROUTES.CREATE_FILE_1} component={CreateDocumentPage} />
    <Route path={ROUTES.CREATE_FILE_2} component={CreateDocumentPage} />
    <Route path={ROUTES.CREATE_SURVEY} component={CreateSurveyPage} />
    <Route path={ROUTES.VIEW_FILE_1} exact component={DocumentDetailsPage} />
    <Route path={ROUTES.VIEW_FILE_2} exact component={DocumentDetailsPage} />
    <Route path={ROUTES.UPDATE_FILE} exact component={DocumentDetailsPage} />
    <Route path={ROUTES.VIEW_SURVEY_1} exact component={DocumentDetailsPage} />
    <Route path={ROUTES.VIEW_SURVEY_2} exact component={DocumentDetailsPage} />
    <Route path={ROUTES.UPDATE_SURVEY} exact component={SurveyDetailsPage} />
    <Route path={ROUTES.GROUPS} exact component={GroupsList} />
    <Route path={ROUTES.VIEW_GROUP} exact component={GroupDetails} />
    <Route path={ROUTES.MY_MODULES} exact component={ProductsList} />
    <Route path={ROUTES.VIEW_PRODUCT} exact component={ProductDetails} />
    <Route path={ROUTES.HOME} exact component={PageList} />
    <Route path={ROUTES.LOGIN} exact>
      <Redirect to={ROUTES.HOME}></Redirect>
    </Route>
    <Route path='*' component={NotFoundPage} />
  </Switch>
);

export const UserRouts = (
  <Switch>
    <Route path={ROUTES.PROFILE} exact component={UserProfile} />
    <Route path={ROUTES.RESULTS} exact component={UserResults} />
    {/* <Route path={ROUTES.CHANGE_PASSWORD} exact component={UserProfile} /> */}

    <Route path={ROUTES.WIKI} component={UserProducts} />
    <Route path={ROUTES.EXPERT_KNOWLEDGE} component={UserProducts} />
    <Route path={ROUTES.MY_MODULES} component={UserProducts} />
    <Route path={ROUTES.USERCONTENT_1} exact component={UserContentPageFrame} />
    <Route path={ROUTES.USERCONTENT_2} exact component={UserContentPageFrame} />

    <Route path={ROUTES.HOME} exact component={UserProducts} />
    <Route path={ROUTES.LOGIN} exact>
      <Redirect to={ROUTES.HOME}></Redirect>
    </Route>
    {/*<Route path="/surveys" component={} />*/}
    {/*<Route path="/products" component={Products} />*/}
    {/*<Route path="/surveys" component={UserSurvey} />*/}
    {/*<Route path="/surveys/:id" component={UserSurvey} />*/}
    {/*<Route path="/campus" component={WikiPage} />*/}
    {/*<Route path="/file/:id" component={WikiPage} />*/}
    {/*<Route path="/update-file/:id" component={WikiPage} />*/}
    {/*<Route path="/404" component={NotFoundPage} />*/}
    <Route path='*' component={NotFoundPage} />
  </Switch>
);

export const UnauthRouts = (
  <Switch>
    <Route path={ROUTES.WIKI_SANITAS} exact component={UserProducts} />
    <Route path={ROUTES.WIKI_USERCONTENT_1} exact component={UserContentPageFrame} />
    <Route path={ROUTES.WIKI_USERCONTENT_2} exact component={UserContentPageFrame} />
    <Route path={ROUTES.NOT_FOUND} component={NotFoundPage} />
    <Route path='*' component={NotFoundPage} />
  </Switch>
);
