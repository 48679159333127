import { Route, withRouter } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import Loader from 'react-loaders';
import Notification from 'components/Notification';
import { ToastContainer } from 'react-toastify';
import { ROUTES } from '../../utils/constants';

const RegisterPage = lazy(() => import('pages/Register'));
const ResetPasswordPage = lazy(() => import('pages/ResetPassword'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPassword'));
const ActivateAccountPage = lazy(() => import('pages/ActivateAccount'));
const RegisterSuccessPage = lazy(() => import('pages/RegisterSuccess'));
const UserProducts = lazy(() => import('pages/UserProducts'));
const UserContentPageFrame = lazy(() => import('../../pages/UserContentPage/UserContentPageFrame'));

const RoutesWithoutAuth = (props) => {
  return (
    <Suspense
      fallback={
        <div className='loader-container'>
          <div className='loader-container-inner'>
            <div className='text-center'>
              <Loader type='ball-grid-beat' />
            </div>
            <p className='mt-3'>Loading...</p>
          </div>
        </div>
      }
    >
      <Route path={ROUTES.REGISTER} component={RegisterPage} />
      <Route path={ROUTES.REGISTER_SUCCESS} component={RegisterSuccessPage} />
      <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
      <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
      <Route path={ROUTES.ACTIVATE_ACCOUNT} component={ActivateAccountPage} />
      <Route path={ROUTES.WIKI_SANITAS} exact component={UserProducts} />
      <Route path={ROUTES.WIKI_USERCONTENT_1} exact component={UserContentPageFrame} />
      <Route path={ROUTES.WIKI_USERCONTENT_2} exact component={UserContentPageFrame} />
      <ToastContainer />
      <Notification />
    </Suspense>
  );
};

export default withRouter(RoutesWithoutAuth);
