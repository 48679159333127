import { takeEvery, select } from "redux-saga/effects";
import * as commentsActions from "../actions/Comments";
import api from "../utils/api";
import { apiRequest } from "./index";
import { get } from "lodash";

const getComments = apiRequest.bind(
  null,
  commentsActions.getComments,
  api.getComments
);
const createComment = apiRequest.bind(
  null,
  commentsActions.createComment,
  api.createComment
);
const updateComment = apiRequest.bind(
  null,
  commentsActions.updateComment,
  api.updateComment
);
const deleteComment = apiRequest.bind(
  null,
  commentsActions.deleteComment,
  api.deleteComment
);

function* refreshComments() {
  const state = yield select();
  const commentsUserId = get(state, "Comments.commentsUserId");
  const arg = {
    id: commentsUserId,
  };
  yield apiRequest.apply(null, [
    commentsActions.getComments,
    api.getComments,
    arg,
  ]);
}
/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetComments() {
  yield takeEvery(commentsActions.GET_COMMENTS.REQUEST, getComments);
}

export function* watchCreateComment() {
  yield takeEvery(commentsActions.CREATE_COMMENT.REQUEST, createComment);
}

export function* watchUpdateComment() {
  yield takeEvery(commentsActions.UPDATE_COMMENT.REQUEST, updateComment);
}

export function* watchDeleteComment() {
  yield takeEvery(commentsActions.DELETE_COMMENT.REQUEST, deleteComment);
}

export function* watchAddCommentSuccess() {
  yield takeEvery(commentsActions.CREATE_COMMENT.SUCCESS, refreshComments);
}

export function* watchUpdateCommentSuccess() {
  yield takeEvery(commentsActions.UPDATE_COMMENT.SUCCESS, refreshComments);
}

export function* watchDeleteCommentSuccess() {
  yield takeEvery(commentsActions.DELETE_COMMENT.SUCCESS, refreshComments);
}
