import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './styles.scss';

import { Button } from 'react-bootstrap';

export const BackToTopButton = () => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <Button variant='bchange-secondary' className='to-top-btn' onClick={scrollToTop}>
      <FontAwesomeIcon icon={faChevronUp} className='to-top-chevron'></FontAwesomeIcon>
    </Button>
  );
};
