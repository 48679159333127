import Keycloak from 'keycloak-js';
import { keycloakUrl, realm, clientId } from 'config/environment';

const keycloak = new Keycloak({
  url: `${keycloakUrl}/auth`,
  realm: realm,
  clientId: clientId,
  onLoad: 'login-required',
});

export default keycloak;
