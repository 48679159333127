import { createRequestTypes, action } from "../utils/actions";
import { REQUEST, SUCCESS, FAILURE } from "../utils/constants";

export const GET_PRODUCTS = createRequestTypes("GET_PRODUCTS");
export const GET_PRODUCT = createRequestTypes("GET_PRODUCT");
export const GET_PRODUCT_MAPPING = createRequestTypes("GET_PRODUCT_MAPPING");
export const GET_PRODUCT_MAPPINGS = createRequestTypes("GET_PRODUCT_MAPPINGS");
export const CREATE_PRODUCT_MAPPINGS = createRequestTypes(
  "CREATE_PRODUCT_MAPPINGS"
);
export const DELETE_PRODUCT = createRequestTypes("DELETE_PRODUCT");
export const DELETE_PRODUCT_MAPPING = createRequestTypes(
  "DELETE_PRODUCT_MAPPING"
);
export const DELETE_PRODUCT_MAPPINGS = createRequestTypes(
  "DELETE_PRODUCT_MAPPINGS"
);
export const GET_PRODUCT_PREVIEW = createRequestTypes("GET_PRODUCT_PREVIEW");
export const CREATE_PRODUCT_PREVIEW = createRequestTypes(
  "CREATE_PRODUCT_PREVIEW"
);
export const UPDATE_PRODUCT_PREVIEW = createRequestTypes(
  "UPDATE_PRODUCT_PREVIEW"
);
export const GET_ACADEMY_PRODUCTS = createRequestTypes("GET_ACADEMY_PRODUCTS");

export const getProducts = {
  request: () => action(GET_PRODUCTS[REQUEST]),
  success: (payload) => action(GET_PRODUCTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_PRODUCTS[FAILURE], { payload }),
};

export const getProduct = {
  request: (id) => action(GET_PRODUCT[REQUEST], { id }),
  success: (payload) => action(GET_PRODUCT[SUCCESS], { payload }),
  failure: (payload) => action(GET_PRODUCT[FAILURE], { payload }),
};

export const getProductMappings = {
  request: (id) => action(GET_PRODUCT_MAPPINGS[REQUEST], { id }),
  success: (payload) => action(GET_PRODUCT_MAPPINGS[SUCCESS], { payload }),
  failure: (payload) => action(GET_PRODUCT_MAPPINGS[FAILURE], { payload }),
};

export const getProductMapping = {
  request: (id) => action(GET_PRODUCT_MAPPING[REQUEST], { id }),
  success: (payload) => action(GET_PRODUCT_MAPPING[SUCCESS], { payload }),
  failure: (payload) => action(GET_PRODUCT_MAPPING[FAILURE], { payload }),
};

export const createProductMappings = {
  request: (data) => action(CREATE_PRODUCT_MAPPINGS[REQUEST], { data }),
  success: (payload) => action(CREATE_PRODUCT_MAPPINGS[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_PRODUCT_MAPPINGS[FAILURE], { payload }),
};

export const deleteProduct = {
  request: (data) => action(DELETE_PRODUCT[REQUEST], { data }),
  success: (payload) => action(DELETE_PRODUCT[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_PRODUCT[FAILURE], { payload }),
};

export const deleteProductMappingss = {
  request: (data) => action(DELETE_PRODUCT_MAPPINGS[REQUEST], { data }),
  success: (payload) => action(DELETE_PRODUCT_MAPPINGS[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_PRODUCT_MAPPINGS[FAILURE], { payload }),
};

export const deleteProductMappings = {
  request: (id) => action(DELETE_PRODUCT_MAPPING[REQUEST], { id }),
  success: (payload) => action(DELETE_PRODUCT_MAPPING[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_PRODUCT_MAPPING[FAILURE], { payload }),
};

export const getProductPreview = {
  request: (id) => action(GET_PRODUCT_PREVIEW[REQUEST], { id }),
  success: (payload) => action(GET_PRODUCT_PREVIEW[SUCCESS], { payload }),
  failure: (payload) => action(GET_PRODUCT_PREVIEW[FAILURE], { payload }),
};

export const updateProductPreview = {
  request: (data) => action(UPDATE_PRODUCT_PREVIEW[REQUEST], { data }),
  success: (payload) => action(UPDATE_PRODUCT_PREVIEW[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_PRODUCT_PREVIEW[FAILURE], { payload }),
};

export const createProductPreview = {
  request: (data) => action(CREATE_PRODUCT_PREVIEW[REQUEST], { data }),
  success: (payload) => action(CREATE_PRODUCT_PREVIEW[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_PRODUCT_PREVIEW[FAILURE], { payload }),
};

export const getAcademyProducts = {
  request: () => action(GET_ACADEMY_PRODUCTS[REQUEST]),
  success: (payload) => action(GET_ACADEMY_PRODUCTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_ACADEMY_PRODUCTS[FAILURE], { payload }),
};
