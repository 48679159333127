import ThemeOptions from "./ThemeOptions";
import UserRoles from "./UserRoles";
import User from "./User";
import Notifications from "./Notifications";
import Loading from "./Loading";
import Auth from "./Auth";
import Admin from "./Admin";
import Comments from "./Comments";
import Documents from "./Documents";
import Surveys from "./Surveys";
import Groups from "./Groups";
import Products from "./Products";
import Videos from "./Videos";
import Profile from "./Profile";

export default {
  ThemeOptions,
  UserRoles,
  User,
  Notifications,
  Surveys,
  Loading,
  Auth,
  Admin,
  Comments,
  Groups,
  Products,
  Documents,
  Videos,
  Profile,
};
