import React from "react";
import { Col, Button } from "reactstrap";

const Component = () => {
  return (
    <>
      <div className="h-100 bg-grey bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Col md="8" className="mx-auto app-login-box">
            <div className="app-logo-inverse mx-auto mb-3" />

            <div className="modal-dialog w-100 mx-auto">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="h5 modal-title text-center">
                    <h4 className="mt-2">
                      <div className="mb-2">Unerwarteter Fehler</div>
                    </h4>
                    <Button
                      className="mt-2"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Seite neu laden
                    </Button>
                  </div>
                </div>
                <div className="modal-footer clearfix">
                  <div className="float-left">
                    {/*
                                <span className="btn-lg btn btn-link">Recover
                                    Password</span>
                                */}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center text-white opacity-8 mt-3">
              Copyright &copy; 2022 Forum via Sanitas
            </div>
          </Col>
        </div>
      </div>
    </>
  );
};

export default Component;
