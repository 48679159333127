import React from 'react';
import logo from 'assets/utils/images/FVS-Logo.png';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import defaultUserProfilePhoto from 'assets/utils/images/Logout.png';
import { ROUTES } from '../../../utils/constants';
import { Container, Dropdown, Nav, Navbar, Stack } from 'react-bootstrap';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { slide as Menu } from 'react-burger-menu';
import { useMediaQuery } from 'usehooks-ts';
import useCollapse from 'react-collapsed';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const UserHeader = ({ logout, profile, history }) => {
  const isMobile = useMediaQuery('(max-width: 769px)');
  const location = useLocation();

  const { getCollapseProps, getToggleProps, isOpen } = useCollapse();
  return (
    <>
      {isMobile && (
        <Menu right>
          {/* <a className={'user-header__nav-link' + (location.pathname === ROUTES.WIKI ? ' user-header__nav-link-active' : '')} href={ROUTES.WIKI}>
            Wiki Sanitas
          </a> */}
          <a className={'user-header__nav-link' + (location.pathname === ROUTES.EXPERT_KNOWLEDGE ? ' user-header__nav-link-active' : '')} href={ROUTES.EXPERT_KNOWLEDGE}>
            Expertenwissen
          </a>
          <a className={'user-header__nav-link' + (location.pathname === ROUTES.MY_MODULES ? ' user-header__nav-link-active' : '')} href={ROUTES.MY_MODULES}>
            Mein Campus
          </a>
          <a className={'user-header__nav-link' + (location.pathname === ROUTES.RESULTS ? ' user-header__nav-link-active' : '')} href={ROUTES.RESULTS}>
            Ergebnisse
          </a>
          <a className='user-header__buchungsportal' href={ROUTES.SHOP} target="_blank" rel="noopener noreferrer">
            Buchungsportal <FontAwesomeIcon className='user-header__profile-chevron ml-3' icon={faChevronRight}></FontAwesomeIcon>
          </a>
          <div className='user-header__nav-link user-header__submenubtn' {...getToggleProps()}>
            <span>Mein Profil</span>
            {isOpen ? (
              <FontAwesomeIcon className='user-header__profile-chevron' key='closed' icon={faChevronDown}></FontAwesomeIcon>
            ) : (
              <FontAwesomeIcon className='user-header__profile-chevron' key='open' icon={faChevronRight}></FontAwesomeIcon>
            )}
            <img className='user-header__profile-image' alt='' src={profile && (profile.pictureS3link || defaultUserProfilePhoto)} style={{ padding: '1px', backgroundColor: '#E11273' }} />
          </div>
          <section {...getCollapseProps()}>
            <ul className='user-header__submenulist'>
              <li>
                <a className={'user-header__nav-link' + (location.pathname === ROUTES.PROFILE ? ' user-header__nav-link-active' : '')} href={ROUTES.PROFILE}>
                  Persönliche Daten
                </a>
              </li>
              {/* <li>
                <a className={'user-header__nav-link' + (location.pathname === ROUTES.CHANGE_PASSWORD ? ' user-header__nav-link-active' : '')} href={ROUTES.CHANGE_PASSWORD}>
                  Passwort ändern
                </a>
              </li> */}
              <li>
                <Link className='user-header__nav-link' onClick={logout}>
                  Logout
                </Link>
              </li>
            </ul>
          </section>
        </Menu>
      )}
      <Container fluid className='user-header-container'>
        {isMobile && (
          <Navbar.Brand href="https://forumviasanitas.org/">
            <img className='user-header-logo' alt='FVS' src={logo} />
          </Navbar.Brand>
        )}
        {!isMobile && (
          <Stack direction='horizontal' className='user-header-stack'>
            <Navbar>
              <Navbar.Brand href="https://forumviasanitas.org/">
                <img className='user-header-logo' alt='FVS' src={logo} />
              </Navbar.Brand>
              <Navbar.Toggle className='user-header-toggle'></Navbar.Toggle>
              <Navbar.Collapse>
                <Nav>
                  <Stack className='ml-5 user-header-nav' direction='horizontal' gap={4}>
                    {/* <a className={'user-header__nav-link' + (location.pathname === ROUTES.WIKI ? ' user-header__nav-link-active' : '')} href={ROUTES.WIKI}>
                      Wiki Sanitas
                    </a> */}
                    <a className={'user-header__nav-link' + (location.pathname === ROUTES.EXPERT_KNOWLEDGE ? ' user-header__nav-link-active' : '')} href={ROUTES.EXPERT_KNOWLEDGE}>
                      Expertenwissen
                    </a>
                    <a className={'user-header__nav-link' + (location.pathname === ROUTES.MY_MODULES ? ' user-header__nav-link-active' : '')} href={ROUTES.MY_MODULES}>
                      Mein Campus
                    </a>
                    <a className={'user-header__nav-link' + (location.pathname === ROUTES.RESULTS ? ' user-header__nav-link-active' : '')} href={ROUTES.RESULTS}>
                      Ergebnisse
                    </a>
                    <a className='user-header__buchungsportal' href={ROUTES.SHOP} target="_blank" rel="noopener noreferrer">
                      Buchungsportal <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                    </a>
                  </Stack>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Stack direction='horizontal' style={{ marginLeft: 'auto' }}>
              <Link className='mr-2' style={{ color: '#1D1D1D' }} to={ROUTES.PROFILE}>
                {profile && (profile.userDetail.title ? profile.userDetail.title + ' ' : '') + profile.userDetail.firstName + ' ' + profile.userDetail.lastName}
              </Link>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>
                  <img className='user-header__profile-image' alt='' src={profile && (profile.pictureS3link || defaultUserProfilePhoto)} style={{ padding: '1px', backgroundColor: '#E11273' }} />
                </Dropdown.Toggle>
                <Dropdown.Menu data-placement='left-end'>
                  <Dropdown.Item onClick={() => history.push(ROUTES.PROFILE)} style={{ textTransform: 'uppercase' }}>
                    Persönliche Daten
                  </Dropdown.Item>
                  {/* <Dropdown.Item onClick={() => history.push(ROUTES.CHANGE_PASSWORD)} style={{ textTransform: 'uppercase' }}>
                    Passwort ändern
                  </Dropdown.Item> */}
                  <Dropdown.Item onClick={logout} style={{ textTransform: 'uppercase' }}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Stack>
          </Stack>
        )}
      </Container>
    </>
  );
};

const mapStateToProps = ({ Profile }) => ({
  profile: Profile.profile,
});

export default connect(mapStateToProps, null)(UserHeader);
