import React from "react";

import Nav from "../AppNav/VerticalNavWrapper";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PerfectScrollbar from "react-perfect-scrollbar";

//TODO: add dark logo
import logo from "../../assets/utils/images/logo-sanitas.png";

const Sidebar = () => (
  <div className="d-sidebar">
    <img alt="sidebar-icon" src={logo} className="d-menu-logo" />
    <div className="d-sidebar-content">
      <ReactCSSTransitionGroup
        component="div"
        className={"d-sidebar-nav"}
        transitionName="SidebarAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PerfectScrollbar>
          <div className="app-sidebar__inner">
            <Nav />
          </div>
        </PerfectScrollbar>
      </ReactCSSTransitionGroup>
    </div>
  </div>
);

export default Sidebar;
