import { fork, all, call, put } from 'redux-saga/effects';

import {
  watchCreateLicensee,
  watchCreateLicenseeKeycloak,
  watchGetLicensees,
  watchCreateLicenseeSuccess,
  watchCreateLicenseeKeycloakSuccess,
  watchGetLicensee,
  watchUpdateLicensee,
  watchUpdateLicenseeSuccess,
  watchDeleteLicensee,
  watchDeleteLicenseeSuccess,
} from './Admin';

import {
  watchGetVideosRequest,
  watchGetVideoRequest,
  watchCreateVideoRequest,
  watchCreateVideoSuccess,
  watchUpdateVideoRequest,
  watchUpdateVideoSuccess,
  watchDeleteVideoRequest,
  watchDeleteVideoSuccess,
} from './Videos';

import {
  watchGetProductsRequest,
  watchGetProductRequest,
  watchGetProductMappingsRequest,
  watchcreateProductMappingsRequest,
  watchdeleteProductMappingssRequest,
  watchDeleteProductRequest,
  watchDeleteProductSuccess,
  watchdeleteProductMappingsRequest,
  watchdeleteProductMappingssSuccess,
  watchCreateProductPreviewRequest,
  watchGetProductPreviewRequest,
  watchUpdateProductPreviewRequest,
  watchUpdateProductPreviewSuccess,
  watchGetAcademyProductsRequest,
} from './Products';

import {
  watchLoadUsers,
  watchLoadUser,
  watchCreateUser,
  watchUpdateUser,
  watchDeleteUser,
  watchCreateUserKeycloak,
  watchCreateUserKeycloakSuccess,
  watchLoadFilteredUsers,
  watchUpdateUserSuccess,
  watchCreateUserSuccess,
  watchUpdateUserPassword,
  watchUpdateUserPasswordSuccess,
  watchUpdateUserStatus,
} from './User';

import { watchGetToken, watchLogout, watchGetTokenFailure, watchSetUserRole, watchRefreshToken, watchRefreshTokenSuccess, watchRefreshTokenFailure } from './Auth';

import { watchGetComments, watchCreateComment, watchUpdateComment, watchDeleteComment, watchAddCommentSuccess, watchUpdateCommentSuccess, watchDeleteCommentSuccess } from './Comments';

import {
  watchGetProfile,
  watchUpdateProfile,
  watchUploadProfilePhotoRequest,
  watchUploadProfilePhotoSuccess,
  watchUpdatePasswordSuccess,
  watchUpdatePasswordRequest,
  watchUpdateProfileSuccess,
} from './Profile';

import {
  watchGetDocumentsPublicRequest,
  watchGetDocumentsRequest,
  watchgetPublicDocumentsRequest,
  watchGetSharedDocumentsRequest,
  watchGetDocumentRequest,
  watchGetDocumentPublicRequest,
  watchGetUserDocumentsRequest,
  watchCreateDocumentRequest,
  watchUpdateDocumentRequest,
  watchDeleteDocumentRequest,
  watchCreateDocumentSuccess,
  watchUpdateDocumentSuccess,
  watchGetDocumentByVersionRequest,
  watchGetBriefDocumentsRequest,
  watchGetSubpageRequest,
  watchCreateSubpageRequest,
  watchCreateSubpageSuccess,
  watchUpdateSubpageRequest,
  watchUpdateSubpageSuccess,
  watchGetDocumentFailure,
  watchGetVideoLinkRequest,
  watchGetDocumentPdfFileRequest,
  watchDownloadDocumentPdfFileRequest,
} from './Documents';

import {
  watchGetSurveysRequest,
  watchGetSurveyRequest,
  watchGetUserSurveysRequest,
  watchCreateSurveyRequest,
  watchUpdateSurveyRequest,
  watchDeleteSurveyRequest,
  watchCreateSurveySuccess,
  watchUpdateSurveySuccess,
  watchSubmitSurveyRequest,
  watchDeleteSurveySuccess,
  watchCompleteSurveyRequest,
  watchGetUserCompletedSurveysRequest,
} from './Surveys';

import {
  watchGetGroupsRequest,
  watchGetGroupRequest,
  watchGetUserGroupsRequest,
  watchCreateGroupRequest,
  watchUpdateGroupRequest,
  watchDeleteGroupRequest,
  watchGetGroupUsersRequest,
  watchCreateGroupSuccess,
  watchUpdateGroupSuccess,
  watchDeleteGroupSuccess,
  watchAddUserToGroupRequest,
  watchUpdateUserGroupsRequest,
  watchUpdateUserGroupsSuccess,
} from './Groups';

// resuable fetch Subroutine
// entity :  user | repo | starred | stargazers
// apiFn  : api.fetchUser | api.fetchRepo | ...
export function* apiRequest(entity, apiFn, action) {
  const { id, data } = action;
  const { response, error } = id ? yield call(apiFn, id, data) : yield call(apiFn, data);
  if (!error) yield put(entity.success(response));
  else yield put(entity.failure(error));
}

export default function* root() {
  yield all([
    fork(watchLoadUsers),
    fork(watchLoadUser),
    fork(watchCreateUser),
    fork(watchCreateUserKeycloak),
    fork(watchCreateUserKeycloakSuccess),
    fork(watchUpdateUser),
    fork(watchDeleteUser),
    fork(watchGetToken),
    fork(watchLogout),
    fork(watchCreateLicensee),
    fork(watchCreateLicenseeKeycloak),
    fork(watchCreateLicenseeSuccess),
    fork(watchCreateLicenseeKeycloakSuccess),
    fork(watchGetLicensees),
    fork(watchGetLicensee),
    fork(watchUpdateLicensee),
    fork(watchUpdateLicenseeSuccess),
    fork(watchDeleteLicensee),
    fork(watchDeleteLicenseeSuccess),
    fork(watchRefreshToken),
    fork(watchRefreshTokenSuccess),
    fork(watchGetTokenFailure),
    fork(watchRefreshTokenFailure),
    fork(watchLoadFilteredUsers),
    fork(watchUpdateUserSuccess),
    fork(watchCreateUserSuccess),
    fork(watchSetUserRole),
    fork(watchGetComments),
    fork(watchCreateComment),
    fork(watchUpdateComment),
    fork(watchDeleteComment),
    fork(watchAddCommentSuccess),
    fork(watchUpdateCommentSuccess),
    fork(watchDeleteCommentSuccess),
    fork(watchUpdateUserPassword),
    fork(watchUpdateUserPasswordSuccess),
    fork(watchGetDocumentsRequest),
    fork(watchGetDocumentsPublicRequest),
    fork(watchgetPublicDocumentsRequest),
    fork(watchGetUserDocumentsRequest),
    fork(watchGetSharedDocumentsRequest),
    fork(watchGetDocumentRequest),
    fork(watchGetDocumentPublicRequest),
    fork(watchCreateDocumentRequest),
    fork(watchUpdateDocumentRequest),
    fork(watchDeleteDocumentRequest),
    fork(watchCreateDocumentSuccess),
    fork(watchUpdateDocumentSuccess),
    fork(watchGetBriefDocumentsRequest),
    fork(watchGetSurveysRequest),
    fork(watchGetSurveyRequest),
    fork(watchDeleteSurveySuccess),
    fork(watchGetUserSurveysRequest),
    fork(watchCompleteSurveyRequest),
    fork(watchCreateSurveyRequest),
    fork(watchUpdateSurveyRequest),
    fork(watchDeleteSurveyRequest),
    fork(watchCreateSurveySuccess),
    fork(watchUpdateSurveySuccess),
    fork(watchSubmitSurveyRequest),
    fork(watchGetDocumentByVersionRequest),
    fork(watchGetGroupsRequest),
    fork(watchGetGroupRequest),
    fork(watchGetUserGroupsRequest),
    fork(watchCreateGroupRequest),
    fork(watchUpdateGroupRequest),
    fork(watchDeleteGroupRequest),
    fork(watchCreateGroupSuccess),
    fork(watchUpdateGroupSuccess),
    fork(watchDeleteGroupSuccess),
    fork(watchGetGroupUsersRequest),
    fork(watchAddUserToGroupRequest),
    fork(watchGetProductsRequest),
    fork(watchGetProductRequest),
    fork(watchGetProductMappingsRequest),
    fork(watchcreateProductMappingsRequest),
    fork(watchdeleteProductMappingssRequest),
    fork(watchDeleteProductRequest),
    fork(watchDeleteProductSuccess),
    fork(watchdeleteProductMappingsRequest),
    fork(watchdeleteProductMappingssSuccess),
    fork(watchUpdateUserGroupsRequest),
    fork(watchUpdateUserGroupsSuccess),
    fork(watchGetProductPreviewRequest),
    fork(watchCreateProductPreviewRequest),
    fork(watchUpdateProductPreviewRequest),
    fork(watchGetAcademyProductsRequest),
    fork(watchUpdateProductPreviewSuccess),
    fork(watchGetVideosRequest),
    fork(watchGetVideoRequest),
    fork(watchCreateVideoRequest),
    fork(watchCreateVideoSuccess),
    fork(watchUpdateVideoRequest),
    fork(watchUpdateVideoSuccess),
    fork(watchDeleteVideoRequest),
    fork(watchDeleteVideoSuccess),
    fork(watchGetProfile),
    fork(watchUpdateProfile),
    fork(watchUploadProfilePhotoRequest),
    fork(watchGetUserCompletedSurveysRequest),
    fork(watchGetSubpageRequest),
    fork(watchCreateSubpageRequest),
    fork(watchCreateSubpageSuccess),
    fork(watchUploadProfilePhotoSuccess),
    fork(watchUpdatePasswordSuccess),
    fork(watchUpdateProfileSuccess),
    fork(watchUpdatePasswordRequest),
    fork(watchUpdateSubpageRequest),
    fork(watchUpdateSubpageSuccess),
    fork(watchGetDocumentFailure),
    fork(watchGetVideoLinkRequest),
    fork(watchGetDocumentPdfFileRequest),
    fork(watchDownloadDocumentPdfFileRequest),
    fork(watchUpdateUserStatus),
  ]);
}
