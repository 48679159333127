import * as ActionTypes from "actions/Videos";
import * as AuthActionTypes from "actions/Auth";

const initialState = {
  videos: [],
  video: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.GET_VIDEOS.SUCCESS:
      return {
        ...state,
        videos: action.payload,
      };
    case ActionTypes.GET_VIDEO.SUCCESS:
      return {
        ...state,
        video: action.payload,
      };
    default:
      return state;
  }
}
