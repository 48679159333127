import React, { Fragment } from "react";
import { connect } from "react-redux";
import UserFooter from "./UserFooter";
import AdminFooter from "./AdminFooter";

class AppFooter extends React.Component {
  render() {
    const { userRole } = this.props;

    return (
      <Fragment>
        {userRole === "admin" ? <AdminFooter /> : <UserFooter />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ UserRoles }) => ({
  userRole: UserRoles.userRole,
});

export default connect(mapStateToProps, null)(AppFooter);
