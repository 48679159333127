export const SET_LOADING = "SET_LOADING";
export const SET_PROGRESS = "SET_PROGRESS";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setProgress = (payload) => ({
  type: SET_PROGRESS,
  payload,
});
