import { takeEvery, put, select } from "redux-saga/effects";
import * as adminActions from "../actions/Admin";
import api from "../utils/api";
import { apiRequest } from "./index";
import history from "../utils/history";
import { get } from "lodash";

const createLicenseeUser = apiRequest.bind(
  null,
  adminActions.createLicenseeKeycloak,
  api.createLicenseeKeycloak
);
const createLicenseeInfo = apiRequest.bind(
  null,
  adminActions.createLicensee,
  api.createLicensee
);
const getAllLicensees = apiRequest.bind(
  null,
  adminActions.getLicensees,
  api.getLicensees
);
const getLicenseeById = apiRequest.bind(
  null,
  adminActions.getLicensee,
  api.getLicensee
);
const updateLicenseeById = apiRequest.bind(
  null,
  adminActions.updateLicensee,
  api.updateLicensee
);
const deleteLicenseeById = apiRequest.bind(
  null,
  adminActions.deleteLicensee,
  api.deleteLicensee
);

function* handleCreateLicenseeKeycloak() {
  yield history.push("/create-licensee?accountInfo=true");
}

function* handleCreateLicenseeInfo(action) {
  const state = yield select();
  const allUsers = get(state, "Admin.licensees", []);
  const pageSize = get(state, "Admin.licenseePageSize", 20);
  const tableFilter = get(state, "Admin.tableFilter", "");
  const lastPage = tableFilter
    ? 0
    : Math.floor((allUsers.length + 1) / pageSize) - 1;
  yield put({
    type: adminActions.SET_LICENSEE_PAGE,
    payload: lastPage > 0 ? lastPage : 0,
  });
  yield history.push("/licensee");
}

function* handleUpdateLicensee(action) {
  // const state = yield select();
  // const allUsers = get(state, 'Admin.licensees', []);
  // const pageSize = get(state, 'Admin.licenseePageSize', 20);
  // const lastPage = Math.floor((allUsers.length + 1) / pageSize) -1 ;
  // yield put({ type: adminActions.SET_LICENSEE_PAGE, payload: lastPage > 0 ? lastPage : 0 });
  yield history.push("/licensee");
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetLicensees() {
  yield takeEvery(adminActions.GET_LICENSEES.REQUEST, getAllLicensees);
}

export function* watchCreateLicenseeKeycloak() {
  yield takeEvery(
    adminActions.CREATE_LICENSEE_KEYCLOAK.REQUEST,
    createLicenseeUser
  );
}

export function* watchCreateLicensee() {
  yield takeEvery(adminActions.CREATE_LICENSEE.REQUEST, createLicenseeInfo);
}

export function* watchCreateLicenseeKeycloakSuccess() {
  yield takeEvery(
    adminActions.CREATE_LICENSEE_KEYCLOAK.SUCCESS,
    handleCreateLicenseeKeycloak
  );
}

export function* watchCreateLicenseeSuccess() {
  yield takeEvery(
    adminActions.CREATE_LICENSEE.SUCCESS,
    handleCreateLicenseeInfo
  );
}

export function* watchGetLicensee() {
  yield takeEvery(adminActions.GET_LICENSEE.REQUEST, getLicenseeById);
}

export function* watchUpdateLicensee() {
  yield takeEvery(adminActions.UPDATE_LICENSEE.REQUEST, updateLicenseeById);
}

export function* watchUpdateLicenseeSuccess() {
  yield takeEvery(adminActions.UPDATE_LICENSEE.SUCCESS, handleUpdateLicensee);
}

export function* watchDeleteLicensee() {
  yield takeEvery(adminActions.DELETE_LICENSEE.REQUEST, deleteLicenseeById);
}

export function* watchDeleteLicenseeSuccess() {
  yield takeEvery(adminActions.DELETE_LICENSEE.SUCCESS, getAllLicensees);
}
