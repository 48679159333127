import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_DOCUMENTS = createRequestTypes('GET_DOCUMENTS');
export const GET_DOCUMENTS_PUBLIC = createRequestTypes('GET_DOCUMENTS_PUBLIC');
export const GET_SUBPAGE = createRequestTypes('GET_SUBPAGE');
export const UPDATE_SUBPAGE = createRequestTypes('UPDATE_SUBPAGE');
export const CREATE_SUBPAGE = createRequestTypes('CREATE_SUBPAGE');
export const GET_BRIEF_DOCUMENTS = createRequestTypes('GET_BRIEF_DOCUMENTS');
export const GET_PUBLIC_DOCUMENTS = createRequestTypes('GET_PUBLIC_DOCUMENTS');
export const GET_SHARED_DOCUMENTS = createRequestTypes('GET_SHARED_DOCUMENTS');
export const GET_USER_DOCUMENTS = createRequestTypes('GET_USER_DOCUMENTS');
export const GET_DOCUMENT = createRequestTypes('GET_DOCUMENT');
export const GET_DOCUMENT_PUBLIC = createRequestTypes('GET_DOCUMENT_PUBLIC');
export const CREATE_DOCUMENT = createRequestTypes('CREATE_DOCUMENT');
export const UPDATE_DOCUMENT = createRequestTypes('UPDATE_DOCUMENT');
export const DELETE_DOCUMENT = createRequestTypes('DELETE_DOCUMENT');
export const GET_DOCUMENT_BY_VERSION = createRequestTypes('GET_DOCUMENT_BY_VERSION');
export const GET_DOCUMENT_PERMISSIONS = createRequestTypes('GET_DOCUMENT_PERMISSIONS');
export const CREATE_DOCUMENT_PERMISSIONS = createRequestTypes('CREATE_DOCUMENT_PERMISSIONS');
export const DELETE_DOCUMENT_PERMISSIONS = createRequestTypes('DELETE_DOCUMENT_PERMISSIONS');
export const GET_VIDEO_LINK = createRequestTypes('GET_VIDEO_LINK');
export const GET_DOCUMENT_PDF_FILE = createRequestTypes('GET_DOCUMENT_PDF_FILE');
export const DOWNLOAD_DOCUMENT_PDF_FILE = createRequestTypes('DOWNLOAD_DOCUMENT_PDF_FILE');
export const SET_PRODUCTS_FILTER = 'SET_PRODUCTS_FILTER';
export const SET_DOCUMENTS_FILTER = 'SET_DOCUMENTS_FILTER';
export const SET_PUBLIC_DOCUMENT = 'SET_PUBLIC_DOCUMENT';

export const RESET_DOCUMENTS_FILTER = 'RESET_DOCUMENTS_FILTER';

export const setPublicDocument = (payload) => ({
  type: SET_PUBLIC_DOCUMENT,
  payload,
});

export const getDocuments = {
  request: (data, meta) => action(GET_DOCUMENTS[REQUEST], { data, meta }),
  success: (payload) => action(GET_DOCUMENTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_DOCUMENTS[FAILURE], { payload }),
};

export const getDocumentsPublic = {
  request: (data, meta) => action(GET_DOCUMENTS_PUBLIC[REQUEST], { data, meta }),
  success: (payload) => action(GET_DOCUMENTS_PUBLIC[SUCCESS], { payload }),
  failure: (payload) => action(GET_DOCUMENTS_PUBLIC[FAILURE], { payload }),
};

export const getSubpage = {
  request: (data) => action(GET_SUBPAGE[REQUEST], { data }),
  success: (payload) => action(GET_SUBPAGE[SUCCESS], { payload }),
  failure: (payload) => action(GET_SUBPAGE[FAILURE], { payload }),
};

export const updateSubpage = {
  request: (id, data) => action(UPDATE_SUBPAGE[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_SUBPAGE[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_SUBPAGE[FAILURE], { payload }),
};

export const createSubpage = {
  request: (id, data) => action(CREATE_SUBPAGE[REQUEST], { id, data }),
  success: (payload) => action(CREATE_SUBPAGE[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_SUBPAGE[FAILURE], { payload }),
};

export const getBriefDocuments = {
  request: () => action(GET_BRIEF_DOCUMENTS[REQUEST]),
  success: (payload) => action(GET_BRIEF_DOCUMENTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_BRIEF_DOCUMENTS[FAILURE], { payload }),
};

export const getPublicDocuments = {
  request: () => action(GET_PUBLIC_DOCUMENTS[REQUEST]),
  success: (payload) => action(GET_PUBLIC_DOCUMENTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_PUBLIC_DOCUMENTS[FAILURE], { payload }),
};

export const getUserDocuments = {
  request: (id) => action(GET_USER_DOCUMENTS[REQUEST], { id }),
  success: (payload) => action(GET_USER_DOCUMENTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_USER_DOCUMENTS[FAILURE], { payload }),
};

export const getSharedDocuments = {
  request: () => action(GET_SHARED_DOCUMENTS[REQUEST]),
  success: (payload) => action(GET_SHARED_DOCUMENTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_SHARED_DOCUMENTS[FAILURE], { payload }),
};

export const getDocument = {
  request: (id) => action(GET_DOCUMENT[REQUEST], { id }),
  success: (payload) => action(GET_DOCUMENT[SUCCESS], { payload }),
  failure: (payload) => action(GET_DOCUMENT[FAILURE], { payload }),
};

export const getDocumentPublic = {
  request: (id) => action(GET_DOCUMENT_PUBLIC[REQUEST], { id }),
  success: (payload) => action(GET_DOCUMENT_PUBLIC[SUCCESS], { payload }),
  failure: (payload) => action(GET_DOCUMENT_PUBLIC[FAILURE], { payload }),
};

export const getDocumentByVersion = {
  request: (data) => action(GET_DOCUMENT_BY_VERSION[REQUEST], { data }),
  success: (payload) => action(GET_DOCUMENT_BY_VERSION[SUCCESS], { payload }),
  failure: (payload) => action(GET_DOCUMENT_BY_VERSION[FAILURE], { payload }),
};

export const createDocument = {
  request: (data) => action(CREATE_DOCUMENT[REQUEST], { data }),
  success: (payload) => action(CREATE_DOCUMENT[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_DOCUMENT[FAILURE], { payload }),
};

export const updateDocument = {
  request: (id, data) => action(UPDATE_DOCUMENT[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_DOCUMENT[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_DOCUMENT[FAILURE], { payload }),
};

export const deleteDocument = {
  request: (id) => action(DELETE_DOCUMENT[REQUEST], { id }),
  success: (payload) => action(DELETE_DOCUMENT[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_DOCUMENT[FAILURE], { payload }),
};

export const createDocumentPermissions = {
  request: (id, data) => action(CREATE_DOCUMENT_PERMISSIONS[REQUEST], { id, data }),
  success: (payload) => action(CREATE_DOCUMENT_PERMISSIONS[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_DOCUMENT_PERMISSIONS[FAILURE], { payload }),
};

export const getDocumentPermissions = {
  request: (id) => action(GET_DOCUMENT_PERMISSIONS[REQUEST], { id }),
  success: (payload) => action(GET_DOCUMENT_PERMISSIONS[SUCCESS], { payload }),
  failure: (payload) => action(GET_DOCUMENT_PERMISSIONS[FAILURE], { payload }),
};

export const deleteDocumentPermissions = {
  request: (id) => action(DELETE_DOCUMENT_PERMISSIONS[REQUEST], { id }),
  success: (payload) => action(DELETE_DOCUMENT_PERMISSIONS[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_DOCUMENT_PERMISSIONS[FAILURE], { payload }),
};

export const getVideoLink = {
  request: (id) => action(GET_VIDEO_LINK[REQUEST], { id }),
  success: (payload) => action(GET_VIDEO_LINK[SUCCESS], { payload }),
  failure: (payload) => action(GET_VIDEO_LINK[FAILURE], { payload }),
};
export const getDocumentPdfFile = {
  request: (data) => action(GET_DOCUMENT_PDF_FILE[REQUEST], { data }),
  success: (payload) => action(GET_DOCUMENT_PDF_FILE[SUCCESS], { payload }),
  failure: (payload) => action(GET_DOCUMENT_PDF_FILE[FAILURE], { payload }),
};

export const downloadDocumentPdfFile = {
  request: (data, pdfFileName) => action(DOWNLOAD_DOCUMENT_PDF_FILE[REQUEST], { data, pdfFileName }),
  success: (payload) => action(DOWNLOAD_DOCUMENT_PDF_FILE[SUCCESS], { payload }),
  failure: (payload) => action(DOWNLOAD_DOCUMENT_PDF_FILE[FAILURE], { payload }),
};

export const setProductsFilter = (payload) => ({
  type: SET_PRODUCTS_FILTER,
  payload,
});

export const setDocumentsFilter = (payload) => ({
  type: SET_DOCUMENTS_FILTER,
  payload,
});

export const resetDocumentsFilter = () => ({
  type: RESET_DOCUMENTS_FILTER,
});
