import * as ActionTypes from '../actions/Auth';

export default function reducer(
  state = {
    user: null,
  },
  action
) {
  switch (action.type) {
    case ActionTypes.GET_TOKEN.FAILURE:
    case ActionTypes.LOGOUT.SUCCESS:
      return state;
    case ActionTypes.GET_TOKEN.SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
