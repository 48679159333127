import React from 'react';
import './styles.scss';
import logo from 'assets/utils/images/FVS-Logo.png';
import { Button, Container, Image, Stack } from 'react-bootstrap';
import facebook from 'assets/utils/images/socials/icon_facebook.svg';
import instagram from 'assets/utils/images/socials/icon_instagram.svg';
import linkedin from 'assets/utils/images/socials/icon_linked_in.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const UserFooter = () => {
  return (
    <Container fluid className='mt-3'>
      <Stack direction='horizontal' className='footer_box'>
        <div>
          <h4 style={{ fontWeight: 'bold' }}>Sie haben Fragen und Wünsche?</h4>
          <p className='footer_contact_line'>
            Senden Sie uns eine E-Mail unter <a href='mailto:office@forumviasanitas.org'>office@forumviasanitas.org</a>
          </p>
          <p className='footer_contact_line'>
            Oder rufen Sie uns an: <a href='tel:+43624621133'>+43 (0) 6246 21133</a>
          </p>
          <p>Mo - Do 8.00 - 17.00 Uhr und Fr 8.00 -13.00 Uhr</p>
          <Stack gap={3} className='footer_btn_group'>
            <a className='btn btn-bchange footer_link' href='https://service.forumviasanitas.org/jahresbeitrag/137/jahresbeitrag-2023'>
              Förderer werden
            </a>
            <a className='btn btn-bchange-secondary footer_link' href='https://forumviasanitas.org/newsletter-anmeldung/'>
              Newsletter bestellen
            </a>
          </Stack>
        </div>

        <div>
          <h4 style={{ fontWeight: 'bold' }}>folgen Sie uns</h4>
          <Stack gap={3} direction='horizontal'>
            <a href='https://www.facebook.com/forumviasanitas/'>
              <Image className='footer_social' src={facebook} />
            </a>
            <a href='https://www.instagram.com/forum_via_sanitas/'>
              <Image className='footer_social' src={instagram} />
            </a>
            <a href='https://www.linkedin.com/company/forumviasanitas'>
              <Image className='footer_social' src={linkedin} />
            </a>
          </Stack>
        </div>
      </Stack>
      <Stack style={{ alignItems: 'center', textAlign: 'center' }} className='mt-3'>
        <img className='d-header-logo' alt='FVS' src={logo} style={{ width: '280px', height: 'auto' }} />
        <p>
          <a href='https://forumviasanitas.org/impressum/' target="_blank" rel="noopener noreferrer">Impressum</a> | <a href='https://forumviasanitas.org/datenschutzerklaerung/' target="_blank" rel="noopener noreferrer">Datenschutz</a>
        </p>
        <p>
          Höglwörthweg 82, 5020 Salzburg | <a href='mailto:office@forumviasanitas.org'>office@forumviasanitas.org</a> | <a href='tel:+43624621133'>+43 (0) 6246 21133</a>
        </p>
        <p style={{ color: '#B0B4B8' }}>Copyright {new Date().getFullYear()} Forum Via Sanitas</p>
      </Stack>
    </Container>
  );
};

export default UserFooter;
