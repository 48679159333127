import * as ActionTypes from "../actions/Admin";
import * as AuthActionTypes from "../actions/Auth";
import { get } from "lodash";

const initialState = {
  licensees: [],
  licensee: null,
  licenseePage: 0,
  licenseeInitialPage: 0,
  licenseePageSize: 100,
  tableFilter: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.SET_LICENSEE_TABLE_FILTER:
      return { ...state, tableFilter: action.payload };
    case ActionTypes.SET_LICENSEE_PAGE:
      return { ...state, licenseeInitialPage: action.payload };
    case ActionTypes.SET_LICENSEE_PAGE_SIZE:
      return { ...state, licenseePageSize: action.payload };
    case ActionTypes.GET_LICENSEES.SUCCESS:
      return {
        ...state,
        licensees: action.payload,
        licenseePage: get(action, "payload.currentPage", 0),
      };
    case ActionTypes.GET_LICENSEE.SUCCESS:
      return { ...state, licensee: action.payload };
    default:
      return state;
  }
}
