import { Client } from "@stomp/stompjs";
import { store } from "../";
import { setProgress, setLoading } from "actions/Loading";
import { fileProcessingServiceUrl } from "config/environment";

const uploadProcessing = (id) => {
  return new Promise(function (resolve) {
    const client = new Client({
      brokerURL: fileProcessingServiceUrl,
      forceBinaryWSFrames: true,
      appendMissingNULLonIncoming: true,
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.onConnect = function (frame) {
      const progressSubscription = client.subscribe(
        `/article/convert/video-progress/${id}`,
        async (msg) => {
          msg.ack();
          const progressJSON = new TextDecoder("utf-8").decode(msg._binaryBody);
          const progress = JSON.parse(progressJSON);
          const uploadStatus = progress.progress;
          store.dispatch(setLoading(true));
          store.dispatch(setProgress(uploadStatus.toFixed()));

          if (+uploadStatus.toFixed() > 100) {
            store.dispatch(setLoading(false));
            progressSubscription.unsubscribe();
          }
        }
      );

      const resultSubscription = client.subscribe(
        `/article/video-upload-result/${id}`,
        (msg) => {
          msg.ack();
          const str = new TextDecoder("utf-8").decode(msg._binaryBody);
          const data = JSON.parse(str);
          resolve(data);
          resultSubscription.unsubscribe();
        }
      );
    };

    client.onStompError = function (frame) {
      console.log("Broker reported error: " + frame.headers["message"]);
      console.log("Additional details: " + frame.body);
      store.dispatch(setLoading(false));
    };
    client.activate();
  });
};

export default uploadProcessing;
