import { takeEvery } from "redux-saga/effects";
import { apiRequest } from "./index";
import api from "utils/api";
import * as productActions from "actions/Products";
import { Slide, toast } from "react-toastify";

const getProducts = apiRequest.bind(
  null,
  productActions.getProducts,
  api.getProducts
);
const getProduct = apiRequest.bind(
  null,
  productActions.getProduct,
  api.getProduct
);
const getProductMappings = apiRequest.bind(
  null,
  productActions.getProductMappings,
  api.getProductMappings
);
const createProductMappings = apiRequest.bind(
  null,
  productActions.createProductMappings,
  api.createProductMappings
);
const deleteProduct = apiRequest.bind(
  null,
  productActions.deleteProduct,
  api.deleteProduct
);
const deleteProductMappings = apiRequest.bind(
  null,
  productActions.deleteProductMappings,
  api.deleteProductMappings
);
const getProductPreview = apiRequest.bind(
  null,
  productActions.getProductPreview,
  api.getProductPreview
);
const createProductPreview = apiRequest.bind(
  null,
  productActions.createProductPreview,
  api.createProductPreview
);
const updateProductPreview = apiRequest.bind(
  null,
  productActions.updateProductPreview,
  api.updateProductPreview
);
const getAcademyProducts = apiRequest.bind(
  null,
  productActions.getAcademyProducts,
  api.getAcademyProducts
);

/******************************************************************************/
/******************************* WATCHERS *************************************/

function* updateSuccees() {
  yield toast("Erfolgreich gespeichert", {
    transition: Slide,
    closeButton: true,
    autoClose: true,
    position: "bottom-center",
    type: "success",
  });
}

export function* watchGetProductsRequest() {
  yield takeEvery(productActions.GET_PRODUCTS.REQUEST, getProducts);
}

export function* watchGetProductRequest() {
  yield takeEvery(productActions.GET_PRODUCT.REQUEST, getProduct);
}

export function* watchGetProductMappingsRequest() {
  yield takeEvery(
    productActions.GET_PRODUCT_MAPPINGS.REQUEST,
    getProductMappings
  );
}

export function* watchcreateProductMappingsRequest() {
  yield takeEvery(
    productActions.CREATE_PRODUCT_MAPPINGS.REQUEST,
    createProductMappings
  );
}

export function* watchdeleteProductMappingsRequest() {
  yield takeEvery(
    productActions.DELETE_PRODUCT_MAPPING.REQUEST,
    deleteProductMappings
  );
}

export function* watchdeleteProductMappingssRequest() {
  yield takeEvery(
    productActions.DELETE_PRODUCT_MAPPINGS.REQUEST,
    deleteProductMappings
  );
}

export function* watchdeleteProductMappingssSuccess() {
  yield takeEvery(
    productActions.DELETE_PRODUCT_MAPPINGS.SUCCESS,
    getProductMappings
  );
}

export function* watchDeleteProductRequest() {
  yield takeEvery(productActions.DELETE_PRODUCT.REQUEST, deleteProduct);
}

export function* watchDeleteProductSuccess() {
  yield takeEvery(productActions.DELETE_PRODUCT.SUCCESS, getProducts);
}

export function* watchGetProductPreviewRequest() {
  yield takeEvery(
    productActions.GET_PRODUCT_PREVIEW.REQUEST,
    getProductPreview
  );
}

export function* watchCreateProductPreviewRequest() {
  yield takeEvery(
    productActions.CREATE_PRODUCT_PREVIEW.REQUEST,
    createProductPreview
  );
}

export function* watchUpdateProductPreviewRequest() {
  yield takeEvery(
    productActions.UPDATE_PRODUCT_PREVIEW.REQUEST,
    updateProductPreview
  );
}

export function* watchCreateProductPreviewSuccess() {
  yield takeEvery(productActions.CREATE_PRODUCT_PREVIEW.SUCCESS, updateSuccees);
}

export function* watchUpdateProductPreviewSuccess() {
  yield takeEvery(productActions.UPDATE_PRODUCT_PREVIEW.SUCCESS, updateSuccees);
}

export function* watchGetAcademyProductsRequest() {
  yield takeEvery(
    productActions.GET_ACADEMY_PRODUCTS.REQUEST,
    getAcademyProducts
  );
}
