import AWS from "aws-sdk";
import { bucket } from "config/environment";

const s3 = new AWS.S3({
  version: "latest",
  region: process.env.REACT_APP_AWS_REGION,
  endpoint: process.env.REACT_APP_AWS_ENDPOINT,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  },
});

export const getSignedUrl = (key, dir = "mp4_samples") => {
  if (!key) return "";
  const url = s3.getSignedUrl("getObject", {
    Bucket: bucket,
    Key: `${dir}/${key}`,
    Expires: 86400,
  });
  return url;
};
