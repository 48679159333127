import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import MetisMenu from "react-metismenu";

import { AdminNav } from "./NavItems";

class Nav extends Component {
  render() {
    const { userRole } = this.props;
    return (
      <>
        {userRole === "user" && null}
        {userRole === "admin" && (
          <>
            <h5 className="app-sidebar__heading">ADMIN DASHBOARD</h5>
            <MetisMenu
              content={AdminNav}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}
      </>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

const mapStateToProps = ({ UserRoles }) => ({
  userRole: UserRoles.userRole,
});

export default connect(mapStateToProps, null)(withRouter(Nav));
