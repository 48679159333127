import { put, takeEvery } from 'redux-saga/effects';
import * as userActions from 'actions/User';
import api from 'utils/api';
import { apiRequest } from './index';
import { get } from 'lodash';
import history from 'utils/history';
import { Slide, toast } from 'react-toastify';

/***************************** Subroutines ************************************/

const fetchUsers = apiRequest.bind(null, userActions.users, api.getUsers);
const fetchFilteredUsers = apiRequest.bind(null, userActions.getFilteredUsers, api.getFilteredUsers);
const fetchUser = apiRequest.bind(null, userActions.getUser, api.getUser);
const addUser = apiRequest.bind(null, userActions.createUser, api.createUser);
const createUserAccount = apiRequest.bind(null, userActions.createUserKeycloak, api.createUserKeycloak);
const editUser = apiRequest.bind(null, userActions.updateUser, api.updateUser);
const updateUserStatus = apiRequest.bind(null, userActions.updateUserStatus, api.updateUserStatus);
const updateUserPasswordByAdmin = apiRequest.bind(
  null,
  userActions.updateUserPasswordByAdmin,
  api.updateUserPasswordByAdmin
);

function* handleCreateUserAccount() {
  yield history.push('/create-user?accountInfo=true');
}

function* removeUser({ data }) {
  const { error } = yield api.deleteUser(data);
  if (!error) return yield put({ type: userActions.DELETE_USER.SUCCESS, payload: data });
  put({ type: userActions.DELETE_USER.ERROR });
}

function* handleChangeUser(action) {
  if (get(action.payload, 'options') === 'redirect') {
    return yield history.push('/users');
  } else {
    yield history.push(`/edit-user/${action.payload.userId}`);

    yield toast('Gespeichert', {
      transition: Slide,
      closeButton: true,
      closeOnClick: true,
      autoClose: true,
      position: 'bottom-center',
      type: 'success',
    });
  }
}

function* handleCreateUser(action) {
  if (get(action.payload, 'options') === 'redirect') {
    return yield history.push('/users');
  } else {
    yield history.push(`/edit-user/${action.payload.id}`);

    yield toast('Gespeichert', {
      transition: Slide,
      closeButton: true,
      closeOnClick: true,
      autoClose: true,
      position: 'bottom-center',
      type: 'success',
    });
  }
}

function* handlePasswordUpdateSusccess() {
  yield toast('Passwort erfolgreich zurück gesetzt', {
    transition: Slide,
    closeButton: true,
    closeOnClick: true,
    autoClose: true,
    position: 'bottom-center',
    type: 'success',
  });
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

// watch licensee users update
export function* watchLoadUsers() {
  yield takeEvery(userActions.USERS.REQUEST, fetchUsers);
}

export function* watchLoadUser(action) {
  yield takeEvery(userActions.GET_USER.REQUEST, fetchUser);
}

export function* watchCreateUser() {
  yield takeEvery(userActions.CREATE_USER.REQUEST, addUser);
}

export function* watchUpdateUser() {
  yield takeEvery(userActions.UPDATE_USER.REQUEST, editUser);
}

export function* watchUpdateUserStatus() {
  yield takeEvery(userActions.UPDATE_USER_STATUS.REQUEST, updateUserStatus);
}

export function* watchUpdateUserSuccess() {
  yield takeEvery(userActions.UPDATE_USER.SUCCESS, handleChangeUser);
}

export function* watchCreateUserSuccess() {
  yield takeEvery(userActions.CREATE_USER.SUCCESS, handleCreateUser);
}

export function* watchDeleteUser() {
  yield takeEvery(userActions.DELETE_USER.REQUEST, removeUser);
}

export function* watchCreateUserKeycloak() {
  yield takeEvery(userActions.CREATE_USER_KEYCLOAK.REQUEST, createUserAccount);
}

export function* watchCreateUserKeycloakSuccess() {
  yield takeEvery(userActions.CREATE_USER_KEYCLOAK.SUCCESS, handleCreateUserAccount);
}

export function* watchLoadFilteredUsers() {
  yield takeEvery(userActions.GET_FILTERED_USERS.REQUEST, fetchFilteredUsers);
}

export function* watchUpdateUserPassword() {
  yield takeEvery(userActions.UPDATE_USER_PASSWORD.REQUEST, updateUserPasswordByAdmin);
}

export function* watchUpdateUserPasswordSuccess() {
  yield takeEvery(userActions.UPDATE_USER_PASSWORD.SUCCESS, handlePasswordUpdateSusccess);
}
