import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_PROFILE = createRequestTypes('GET_PROFILE');
export const UPDATE_PROFILE = createRequestTypes('UPDATE_PROFILE');
export const UPDATE_PASSWORD = createRequestTypes('UPDATE_PASSWORD');
export const UPDATE_PROFILE_ADRESS = createRequestTypes('UPDATE_PROFILE_ADRESS');
export const UPLOAD_PROFILE_PHOTO = createRequestTypes('UPLOAD_PROFILE_PHOTO');
export const UPDATE_PROFILE_PASSWORD = createRequestTypes('UPDATE_PROFILE_PASSWORD');

export const getProfile = {
  request: (id) => action(GET_PROFILE[REQUEST], { id }),
  success: (payload) => action(GET_PROFILE[SUCCESS], { payload }),
  failure: (payload) => action(GET_PROFILE[FAILURE], { payload }),
};

export const updateProfile = {
  request: (id, data) => action(UPDATE_PROFILE[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_PROFILE[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_PROFILE[FAILURE], { payload }),
};

export const uploadProfilePhoto = {
  request: (id, data) => action(UPLOAD_PROFILE_PHOTO[REQUEST], { id, data }),
  success: (payload) => action(UPLOAD_PROFILE_PHOTO[SUCCESS], { payload }),
  failure: (payload) => action(UPLOAD_PROFILE_PHOTO[FAILURE], { payload }),
};

export const updateProfilePassword = {
  request: (data) => action(UPDATE_PROFILE_PASSWORD[REQUEST], { data }),
  success: (payload) => action(UPDATE_PROFILE_PASSWORD[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_PROFILE_PASSWORD[FAILURE], { payload }),
};

export const updatePassword = {
  request: (data) => action(UPDATE_PASSWORD[REQUEST], { data }),
  success: (payload) => action(UPDATE_PASSWORD[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_PASSWORD[FAILURE], { payload }),
};
