import * as ActionTypes from "actions/Products";
import * as AuthActionTypes from "actions/Auth";

const initialState = {
  products: [],
  productMappings: [],
  productMapping: {},
  productPreview: {},
  academyProducts: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.GET_PRODUCT_MAPPINGS.SUCCESS:
      return {
        ...state,
        productMappings: action.payload,
      };
    case ActionTypes.GET_PRODUCTS.SUCCESS:
      return {
        ...state,
        products: action.payload,
      };
    case ActionTypes.GET_ACADEMY_PRODUCTS.SUCCESS:
      return {
        ...state,
        academyProducts: action.payload,
      };
    case ActionTypes.GET_PRODUCT_PREVIEW.SUCCESS:
      return {
        ...state,
        productPreview: action.payload,
      };
    case ActionTypes.GET_PRODUCT.SUCCESS:
      return {
        ...state,
        product: action.payload,
      };
    case ActionTypes.GET_PRODUCT_MAPPING.SUCCESS:
      return {
        ...state,
        productMapping: action.payload,
      };
    default:
      return state;
  }
}
