import { ROUTES } from '../../utils/constants';

export const AdminNav = [
  // {
  //   label: 'Documents',
  //   to: '/documents',
  //   icon: 'pe-7s-albums'
  // },
  {
    label: 'Pages',
    to: '/pages',
    icon: 'pe-7s-albums',
  },
  {
    label: 'Customers',
    to: '/users',
    icon: 'pe-7s-users',
  },
  {
    label: 'Groups',
    to: '/groups',
    icon: 'pe-7s-users',
  },
  {
    label: 'Videos',
    to: '/videos',
    icon: 'pe-7s-video',
  },
  {
    label: 'Products',
    to: ROUTES.MY_MODULES,
    icon: 'pe-7s-shopbag',
  },
  {
    label: 'Surveys',
    to: '/surveys',
    icon: 'pe-7s-study',
  },
];
