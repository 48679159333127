export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const categories = [
  { value: 'Endurance', label: 'Endurance' },
  { value: 'Relax', label: 'Relax' },
  { value: 'Power', label: 'Power' },
  { value: 'Stamina', label: 'Stamina' },
  { value: 'Metabolism', label: 'Metabolism' },
];

export const TYPES = {
  KNOWLEDGE: 'KNOWLEDGE',
  NUTRITION: 'NUTRITION',
  PRODUCT: 'PRODUCT',
};

export const FILE_TYPES = [
  { label: 'Expertenwissen', value: 'KNOWLEDGE' },
  { label: 'Nährstofftipps', value: 'NUTRITION' },
  { label: 'Produkte', value: 'PRODUCT' },
];

export const PAGE_TYPES = [
  { id: 0, label: 'Expertenwissen', value: 'KNOWLEDGE' },
  { id: 1, label: 'Nährstofftipps', value: 'NUTRITION' },
  { id: 2, label: 'Mein Campus', value: 'PRODUCT' },
];

export const languages = [
  { value: 'ab', label: 'Abkhaz', nativeName: 'аҧсуа' },
  { value: 'aa', label: 'Afar', nativeName: 'Afaraf' },
  { value: 'af', label: 'Afrikaans', nativeName: 'Afrikaans' },
  { value: 'ak', label: 'Akan', nativeName: 'Akan' },
  { value: 'sq', label: 'Albanian', nativeName: 'Shqip' },
  { value: 'am', label: 'Amharic', nativeName: 'አማርኛ' },
  { value: 'ar', label: 'Arabic', nativeName: 'العربية' },
  { value: 'an', label: 'Aragonese', nativeName: 'Aragonés' },
  { value: 'hy', label: 'Armenian', nativeName: 'Հայերեն' },
  { value: 'as', label: 'Assamese', nativeName: 'অসমীয়া' },
  { value: 'av', label: 'Avaric', nativeName: 'авар мацӀ, магӀарул мацӀ' },
  { value: 'ae', label: 'Avestan', nativeName: 'avesta' },
  { value: 'ay', label: 'Aymara', nativeName: 'aymar aru' },
  { value: 'az', label: 'Azerbaijani', nativeName: 'azərbaycan dili' },
  { value: 'bm', label: 'Bambara', nativeName: 'bamanankan' },
  { value: 'ba', label: 'Bashkir', nativeName: 'башҡорт теле' },
  { value: 'eu', label: 'Basque', nativeName: 'euskara, euskera' },
  { value: 'be', label: 'Belarusian', nativeName: 'Беларуская' },
  { value: 'bn', label: 'Bengali', nativeName: 'বাংলা' },
  { value: 'bh', label: 'Bihari', nativeName: 'भोजपुरी' },
  { value: 'bi', label: 'Bislama', nativeName: 'Bislama' },
  { value: 'bs', label: 'Bosnian', nativeName: 'bosanski jezik' },
  { value: 'br', label: 'Breton', nativeName: 'brezhoneg' },
  { value: 'bg', label: 'Bulgarian', nativeName: 'български език' },
  { value: 'my', label: 'Burmese', nativeName: 'ဗမာစာ' },
  { value: 'ca', label: 'Catalan; Valencian', nativeName: 'Català' },
  { value: 'ch', label: 'Chamorro', nativeName: 'Chamoru' },
  { value: 'ce', label: 'Chechen', nativeName: 'нохчийн мотт' },
  {
    value: 'ny',
    label: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
  },
  { value: 'zh', label: 'Chinese', nativeName: '中文 (Zhōngwén), 汉语, 漢語' },
  { value: 'cv', label: 'Chuvash', nativeName: 'чӑваш чӗлхи' },
  { value: 'kw', label: 'Cornish', nativeName: 'Kernewek' },
  { value: 'co', label: 'Corsican', nativeName: 'corsu, lingua corsa' },
  { value: 'cr', label: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
  { value: 'hr', label: 'Croatian', nativeName: 'hrvatski' },
  { value: 'cs', label: 'Czech', nativeName: 'česky, čeština' },
  { value: 'da', label: 'Danish', nativeName: 'dansk' },
  { value: 'dv', label: 'Divehi; Dhivehi; Maldivian;', nativeName: 'ދިވެހި' },
  { value: 'nl', label: 'Dutch', nativeName: 'Nederlands, Vlaams' },
  { value: 'en', label: 'English', nativeName: 'English' },
  { value: 'eo', label: 'Esperanto', nativeName: 'Esperanto' },
  { value: 'et', label: 'Estonian', nativeName: 'eesti, eesti keel' },
  { value: 'ee', label: 'Ewe', nativeName: 'Eʋegbe' },
  { value: 'fo', label: 'Faroese', nativeName: 'føroyskt' },
  { value: 'fj', label: 'Fijian', nativeName: 'vosa Vakaviti' },
  { value: 'fi', label: 'Finnish', nativeName: 'suomi, suomen kieli' },
  { value: 'fr', label: 'French', nativeName: 'français, langue française' },
  {
    value: 'ff',
    label: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  { value: 'gl', label: 'Galician', nativeName: 'Galego' },
  { value: 'ka', label: 'Georgian', nativeName: 'ქართული' },
  { value: 'de', label: 'German', nativeName: 'Deutsch' },
  { value: 'el', label: 'Greek, Modern', nativeName: 'Ελληνικά' },
  { value: 'gn', label: 'Guaraní', nativeName: 'Avañeẽ' },
  { value: 'gu', label: 'Gujarati', nativeName: 'ગુજરાતી' },
  {
    value: 'ht',
    label: 'Haitian; Haitian Creole',
    nativeName: 'Kreyòl ayisyen',
  },
  { value: 'ha', label: 'Hausa', nativeName: 'Hausa, هَوُسَ' },
  { value: 'he', label: 'Hebrew (modern)', nativeName: 'עברית' },
  { value: 'hz', label: 'Herero', nativeName: 'Otjiherero' },
  { value: 'hi', label: 'Hindi', nativeName: 'हिन्दी, हिंदी' },
  { value: 'ho', label: 'Hiri Motu', nativeName: 'Hiri Motu' },
  { value: 'hu', label: 'Hungarian', nativeName: 'Magyar' },
  { value: 'ia', label: 'Interlingua', nativeName: 'Interlingua' },
  { value: 'id', label: 'Indonesian', nativeName: 'Bahasa Indonesia' },
  {
    value: 'ie',
    label: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
  },
  { value: 'ga', label: 'Irish', nativeName: 'Gaeilge' },
  { value: 'ig', label: 'Igbo', nativeName: 'Asụsụ Igbo' },
  { value: 'ik', label: 'Inupiaq', nativeName: 'Iñupiaq, Iñupiatun' },
  { value: 'io', label: 'Ido', nativeName: 'Ido' },
  { value: 'is', label: 'Icelandic', nativeName: 'Íslenska' },
  { value: 'it', label: 'Italian', nativeName: 'Italiano' },
  { value: 'iu', label: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
  {
    value: 'ja',
    label: 'Japanese',
    nativeName: '日本語 (にほんご／にっぽんご)',
  },
  { value: 'jv', label: 'Javanese', nativeName: 'basa Jawa' },
  {
    value: 'kl',
    label: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  { value: 'kn', label: 'Kannada', nativeName: 'ಕನ್ನಡ' },
  { value: 'kr', label: 'Kanuri', nativeName: 'Kanuri' },
  { value: 'ks', label: 'Kashmiri', nativeName: 'कश्मीरी, كشميري‎' },
  { value: 'kk', label: 'Kazakh', nativeName: 'Қазақ тілі' },
  { value: 'km', label: 'Khmer', nativeName: 'ភាសាខ្មែរ' },
  { value: 'ki', label: 'Kikuyu, Gikuyu', nativeName: 'Gĩkũyũ' },
  { value: 'rw', label: 'Kinyarwanda', nativeName: 'Ikinyarwanda' },
  { value: 'ky', label: 'Kirghiz, Kyrgyz', nativeName: 'кыргыз тили' },
  { value: 'kv', label: 'Komi', nativeName: 'коми кыв' },
  { value: 'kg', label: 'Kongo', nativeName: 'KiKongo' },
  {
    value: 'ko',
    label: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  { value: 'ku', label: 'Kurdish', nativeName: 'Kurdî, كوردی‎' },
  { value: 'kj', label: 'Kwanyama, Kuanyama', nativeName: 'Kuanyama' },
  { value: 'la', label: 'Latin', nativeName: 'latine, lingua latina' },
  {
    value: 'lb',
    label: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
  },
  { value: 'lg', label: 'Luganda', nativeName: 'Luganda' },
  {
    value: 'li',
    label: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
  },
  { value: 'ln', label: 'Lingala', nativeName: 'Lingála' },
  { value: 'lo', label: 'Lao', nativeName: 'ພາສາລາວ' },
  { value: 'lt', label: 'Lithuanian', nativeName: 'lietuvių kalba' },
  { value: 'lu', label: 'Luba-Katanga', nativeName: '' },
  { value: 'lv', label: 'Latvian', nativeName: 'latviešu valoda' },
  { value: 'gv', label: 'Manx', nativeName: 'Gaelg, Gailck' },
  { value: 'mk', label: 'Macedonian', nativeName: 'македонски јазик' },
  { value: 'mg', label: 'Malagasy', nativeName: 'Malagasy fiteny' },
  { value: 'ms', label: 'Malay', nativeName: 'bahasa Melayu, بهاس ملايو‎' },
  { value: 'ml', label: 'Malayalam', nativeName: 'മലയാളം' },
  { value: 'mt', label: 'Maltese', nativeName: 'Malti' },
  { value: 'mi', label: 'Māori', nativeName: 'te reo Māori' },
  { value: 'mr', label: 'Marathi (Marāṭhī)', nativeName: 'मराठी' },
  { value: 'mh', label: 'Marshallese', nativeName: 'Kajin M̧ajeļ' },
  { value: 'mn', label: 'Mongolian', nativeName: 'монгол' },
  { value: 'na', label: 'Nauru', nativeName: 'Ekakairũ Naoero' },
  {
    value: 'nv',
    label: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí',
  },
  { value: 'nb', label: 'Norwegian Bokmål', nativeName: 'Norsk bokmål' },
  { value: 'nd', label: 'North Ndebele', nativeName: 'isiNdebele' },
  { value: 'ne', label: 'Nepali', nativeName: 'नेपाली' },
  { value: 'ng', label: 'Ndonga', nativeName: 'Owambo' },
  { value: 'nn', label: 'Norwegian Nynorsk', nativeName: 'Norsk nynorsk' },
  { value: 'no', label: 'Norwegian', nativeName: 'Norsk' },
  { value: 'ii', label: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop' },
  { value: 'nr', label: 'South Ndebele', nativeName: 'isiNdebele' },
  { value: 'oc', label: 'Occitan', nativeName: 'Occitan' },
  { value: 'oj', label: 'Ojibwe, Ojibwa', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  {
    value: 'cu',
    label: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  { value: 'om', label: 'Oromo', nativeName: 'Afaan Oromoo' },
  { value: 'or', label: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
  { value: 'os', label: 'Ossetian, Ossetic', nativeName: 'ирон æвзаг' },
  { value: 'pa', label: 'Panjabi, Punjabi', nativeName: 'ਪੰਜਾਬੀ, پنجابی‎' },
  { value: 'pi', label: 'Pāli', nativeName: 'पाऴि' },
  { value: 'fa', label: 'Persian', nativeName: 'فارسی' },
  { value: 'pl', label: 'Polish', nativeName: 'polski' },
  { value: 'ps', label: 'Pashto, Pushto', nativeName: 'پښتو' },
  { value: 'pt', label: 'Portuguese', nativeName: 'Português' },
  { value: 'qu', label: 'Quechua', nativeName: 'Runa Simi, Kichwa' },
  { value: 'rm', label: 'Romansh', nativeName: 'rumantsch grischun' },
  { value: 'rn', label: 'Kirundi', nativeName: 'kiRundi' },
  { value: 'ro', label: 'Romanian, Moldavian, Moldovan', nativeName: 'română' },
  { value: 'ru', label: 'Russian', nativeName: 'русский язык' },
  { value: 'sa', label: 'Sanskrit (Saṁskṛta)', nativeName: 'संस्कृतम्' },
  { value: 'sc', label: 'Sardinian', nativeName: 'sardu' },
  { value: 'sd', label: 'Sindhi', nativeName: 'सिन्धी, سنڌي، سندھی‎' },
  { value: 'se', label: 'Northern Sami', nativeName: 'Davvisámegiella' },
  { value: 'sm', label: 'Samoan', nativeName: 'gagana faa Samoa' },
  { value: 'sg', label: 'Sango', nativeName: 'yângâ tî sängö' },
  { value: 'sr', label: 'Serbian', nativeName: 'српски језик' },
  { value: 'gd', label: 'Scottish Gaelic; Gaelic', nativeName: 'Gàidhlig' },
  { value: 'sn', label: 'Shona', nativeName: 'chiShona' },
  { value: 'si', label: 'Sinhala, Sinhalese', nativeName: 'සිංහල' },
  { value: 'sk', label: 'Slovak', nativeName: 'slovenčina' },
  { value: 'sl', label: 'Slovene', nativeName: 'slovenščina' },
  { value: 'so', label: 'Somali', nativeName: 'Soomaaliga, af Soomaali' },
  { value: 'st', label: 'Southern Sotho', nativeName: 'Sesotho' },
  {
    value: 'es',
    label: 'Spanish; Castilian',
    nativeName: 'español, castellano',
  },
  { value: 'su', label: 'Sundanese', nativeName: 'Basa Sunda' },
  { value: 'sw', label: 'Swahili', nativeName: 'Kiswahili' },
  { value: 'ss', label: 'Swati', nativeName: 'SiSwati' },
  { value: 'sv', label: 'Swedish', nativeName: 'svenska' },
  { value: 'ta', label: 'Tamil', nativeName: 'தமிழ்' },
  { value: 'te', label: 'Telugu', nativeName: 'తెలుగు' },
  { value: 'tg', label: 'Tajik', nativeName: 'тоҷикӣ, toğikī, تاجیکی‎' },
  { value: 'th', label: 'Thai', nativeName: 'ไทย' },
  { value: 'ti', label: 'Tigrinya', nativeName: 'ትግርኛ' },
  {
    value: 'bo',
    label: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
  },
  { value: 'tk', label: 'Turkmen', nativeName: 'Türkmen, Түркмен' },
  { value: 'tl', label: 'Tagalog', nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔' },
  { value: 'tn', label: 'Tswana', nativeName: 'Setswana' },
  { value: 'to', label: 'Tonga (Tonga Islands)', nativeName: 'faka Tonga' },
  { value: 'tr', label: 'Turkish', nativeName: 'Türkçe' },
  { value: 'ts', label: 'Tsonga', nativeName: 'Xitsonga' },
  { value: 'tt', label: 'Tatar', nativeName: 'татарча, tatarça, تاتارچا‎' },
  { value: 'tw', label: 'Twi', nativeName: 'Twi' },
  { value: 'ty', label: 'Tahitian', nativeName: 'Reo Tahiti' },
  { value: 'ug', label: 'Uighur, Uyghur', nativeName: 'Uyƣurqə, ئۇيغۇرچە‎' },
  { value: 'uk', label: 'Ukrainian', nativeName: 'українська' },
  { value: 'ur', label: 'Urdu', nativeName: 'اردو' },
  { value: 'uz', label: 'Uzbek', nativeName: 'zbek, Ўзбек, أۇزبېك‎' },
  { value: 've', label: 'Venda', nativeName: 'Tshivenḓa' },
  { value: 'vi', label: 'Vietnamese', nativeName: 'Tiếng Việt' },
  { value: 'vo', label: 'Volapük', nativeName: 'Volapük' },
  { value: 'wa', label: 'Walloon', nativeName: 'Walon' },
  { value: 'cy', label: 'Welsh', nativeName: 'Cymraeg' },
  { value: 'wo', label: 'Wolof', nativeName: 'Wollof' },
  { value: 'fy', label: 'Western Frisian', nativeName: 'Frysk' },
  { value: 'xh', label: 'Xhosa', nativeName: 'isiXhosa' },
  { value: 'yi', label: 'Yiddish', nativeName: 'ייִדיש' },
  { value: 'yo', label: 'Yoruba', nativeName: 'Yorùbá' },
  { value: 'za', label: 'Zhuang, Chuang', nativeName: 'Saɯ cueŋƅ, Saw cuengh' },
];

export const languageMap = {
  ab: 'Abkhaz',
  aa: 'Afar',
  af: 'Afrikaans',
  ak: 'Akan',
  sq: 'Albanian',
  am: 'Amharic',
  ar: 'Arabic',
  an: 'Aragonese',
  hy: 'Armenian',
  as: 'Assamese',
  av: 'Avaric',
  ae: 'Avestan',
  ay: 'Aymara',
  az: 'Azerbaijani',
  bm: 'Bambara',
  ba: 'Bashkir',
  eu: 'Basque',
  be: 'Belarusian',
  bn: 'Bengali',
  bh: 'Bihari',
  bi: 'Bislama',
  bs: 'Bosnian',
  br: 'Breton',
  bg: 'Bulgarian',
  my: 'Burmese',
  ca: 'Catalan; Valencian',
  ch: 'Chamorro',
  ce: 'Chechen',
  ny: 'Chichewa; Chewa; Nyanja',
  zh: 'Chinese',
  cv: 'Chuvash',
  kw: 'Cornish',
  co: 'Corsican',
  cr: 'Cree',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  dv: 'Divehi; Dhivehi; Maldivian;',
  nl: 'Dutch',
  en: 'English',
  eo: 'Esperanto',
  et: 'Estonian',
  ee: 'Ewe',
  fo: 'Faroese',
  fj: 'Fijian',
  fi: 'Finnish',
  fr: 'French',
  ff: 'Fula; Fulah; Pulaar; Pular',
  gl: 'Galician',
  ka: 'Georgian',
  de: 'German',
  el: 'Greek, Modern',
  gn: 'Guaraní',
  gu: 'Gujarati',
  ht: 'Haitian; Haitian Creole',
  ha: 'Hausa',
  he: 'Hebrew (modern)',
  hz: 'Herero',
  hi: 'Hindi',
  ho: 'Hiri Motu',
  hu: 'Hungarian',
  ia: 'Interlingua',
  id: 'Indonesian',
  ie: 'Interlingue',
  ga: 'Irish',
  ig: 'Igbo',
  ik: 'Inupiaq',
  io: 'Ido',
  is: 'Icelandic',
  it: 'Italian',
  iu: 'Inuktitut',
  ja: 'Japanese',
  jv: 'Javanese',
  kl: 'Kalaallisut, Greenlandic',
  kn: 'Kannada',
  kr: 'Kanuri',
  ks: 'Kashmiri',
  kk: 'Kazakh',
  km: 'Khmer',
  ki: 'Kikuyu, Gikuyu',
  rw: 'Kinyarwanda',
  ky: 'Kirghiz, Kyrgyz',
  kv: 'Komi',
  kg: 'Kongo',
  ko: 'Korean',
  ku: 'Kurdish',
  kj: 'Kwanyama, Kuanyama',
  la: 'Latin',
  lb: 'Luxembourgish, Letzeburgesch',
  lg: 'Luganda',
  li: 'Limburgish, Limburgan, Limburger',
  ln: 'Lingala',
  lo: 'Lao',
  lt: 'Lithuanian',
  lu: 'Luba-Katanga',
  lv: 'Latvian',
  gv: 'Manx',
  mk: 'Macedonian',
  mg: 'Malagasy',
  ms: 'Malay',
  ml: 'Malayalam',
  mt: 'Maltese',
  mi: 'Māori',
  mr: 'Marathi (Marāṭhī)',
  mh: 'Marshallese',
  mn: 'Mongolian',
  na: 'Nauru',
  nv: 'Navajo, Navaho',
  nb: 'Norwegian Bokmål',
  nd: 'North Ndebele',
  ne: 'Nepali',
  ng: 'Ndonga',
  nn: 'Norwegian Nynorsk',
  no: 'Norwegian',
  ii: 'Nuosu',
  nr: 'South Ndebele',
  oc: 'Occitan',
  oj: 'Ojibwe, Ojibwa',
  cu: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
  om: 'Oromo',
  or: 'Oriya',
  os: 'Ossetian, Ossetic',
  pa: 'Panjabi, Punjabi',
  pi: 'Pāli',
  fa: 'Persian',
  pl: 'Polish',
  ps: 'Pashto, Pushto',
  pt: 'Portuguese',
  qu: 'Quechua',
  rm: 'Romansh',
  rn: 'Kirundi',
  ro: 'Romanian, Moldavian, Moldovan',
  ru: 'Russian',
  sa: 'Sanskrit (Saṁskṛta)',
  sc: 'Sardinian',
  sd: 'Sindhi',
  se: 'Northern Sami',
  sm: 'Samoan',
  sg: 'Sango',
  sr: 'Serbian',
  gd: 'Scottish Gaelic; Gaelic',
  sn: 'Shona',
  si: 'Sinhala, Sinhalese',
  sk: 'Slovak',
  sl: 'Slovene',
  so: 'Somali',
  st: 'Southern Sotho',
  es: 'Spanish; Castilian',
  su: 'Sundanese',
  sw: 'Swahili',
  ss: 'Swati',
  sv: 'Swedish',
  ta: 'Tamil',
  te: 'Telugu',
  tg: 'Tajik',
  th: 'Thai',
  ti: 'Tigrinya',
  bo: 'Tibetan Standard, Tibetan, Central',
  tk: 'Turkmen',
  tl: 'Tagalog',
  tn: 'Tswana',
  to: 'Tonga (Tonga Islands)',
  tr: 'Turkish',
  ts: 'Tsonga',
  tt: 'Tatar',
  tw: 'Twi',
  ty: 'Tahitian',
  ug: 'Uighur, Uyghur',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  ve: 'Venda',
  vi: 'Vietnamese',
  vo: 'Volapük',
  wa: 'Walloon',
  cy: 'Welsh',
  wo: 'Wolof',
  fy: 'Western Frisian',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  za: 'Zhuang, Chuang',
};

export const tz = [
  { label: '(GMT - 1) Central African Time', value: -1 },
  { label: '(GMT - 3) Argentina Standard Time', value: -3 },
  { label: '(GMT - 4) Puerto Rico and US Virgin Islands Time', value: -4 },
  { label: '(GMT - 5) Eastern Standard Time', value: -5 },
  { label: '(GMT - 6) Central Standard Time', value: -6 },
  { label: '(GMT - 7) Mountain Standard Time', value: -7 },
  { label: '(GMT - 8) Pacific Standard Time', value: -8 },
  { label: '(GMT - 9) Alaska Standard Time', value: -9 },
  { label: '(GMT - 10) Hawaii Standard Time', value: -10 },
  { label: '(GMT - 11) Midway Islands Time', value: -11 },
  { label: '(GMT) Universal Coordinated Time', value: 0 },
  { label: '(GMT + 1) Central European Time', value: 1 },
  { label: '(GMT + 2) Eastern European Time', value: 2 },
  { label: '(GMT + 3) Eastern European Time', value: 3 },
  { label: '(GMT + 4) Near East Time', value: 4 },
  { label: '(GMT + 5) India Standard Time', value: 5 },
  { label: '(GMT + 6) Bangladesh Standard Time', value: 6 },
  { label: '(GMT + 7) Vietnam Standard Time', value: 7 },
  { label: '(GMT + 8) China Taiwan Time', value: 8 },
  { label: '(GMT + 9) Japan Standard Time', value: 9 },
  { label: '(GMT + 10) Australia Eastern Time', value: 10 },
  { label: '(GMT + 11) Solomon Standard Time', value: 11 },
  { label: '(GMT + 12) New Zealand Standard Time', value: 12 },
];

export const countries_de = [
  { label: 'Österreich', value: 'AT' },
  { label: 'Deutschland', value: 'DE' },
  { label: 'Italien', value: 'IT' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Polen', value: 'PL' },
  { label: 'Schweiz', value: 'CH' },
];

export const countries = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Åland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'AndorrA', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Cote D'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People'S Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People'S Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestinian Territory, Occupied', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'RWANDA', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia and Montenegro', value: 'CS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];

export const frequencies = [
  { label: '7Hz', value: 7 },
  { label: '40Hz', value: 40 },
  { label: '85Hz', value: 85 },
  { label: '100Hz', value: 100 },
];

export const surges = [
  { label: 'Smooth increase', value: 'smooth' },
  { label: 'Max increase', value: 'max' },
];

export const areas = [
  { label: 'Arms', value: 'arms' },
  { label: 'Breast', value: 'breast' },
  { label: 'Abdominal', value: 'abdominal' },
  { label: 'Neck', value: 'neck' },
  { label: 'Upper back', value: 'upper back' },
  { label: 'Lower back', value: 'lower back' },
  { label: 'Legs', value: 'legs' },
  { label: 'Buttocks', value: 'buttocks' },
];

export const docCategories = [
  'Alles',
  'Schaf & Entspannung verbessern',
  'Wohlbefinden & Energie',
  'Gewichtsmanagement verbessern',
  'Immunsystem unterstützen',
  'Konzentration verbessern',
  'Darmgesundheit optimieren',
];

export const categoryOptions = docCategories.map((el) => ({
  value: el,
  label: el,
}));

export const pageSizeOptions = [20, 50, 100];

export const statusOptions = [
  { value: 'NOT_PUBLISHED', label: 'Not Published' },
  { value: 'PUBLISHED_FOR_HOME', label: 'Published for Home' },
  { value: 'PUBLISHED_FOR_STUDIO', label: 'Publish for Studio' },
  { value: 'PUBLISHED_FOR_ALL', label: 'Published for all' },
];

export const activeStatusOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Not Active' },
];

export const statusMap = statusOptions.reduce((acc, el) => {
  return {
    ...acc,
    [el.value]: el.label,
  };
}, {});

export const PROFESSIONS = [
  { label: 'Arzt', value: 'doctor' },
  { label: 'Pharmazeut', value: 'pharmacist' },
  { label: 'Therapeut', value: 'therapist' },
  { label: 'Heilpraktiker', value: 'naturopath' },
  { label: 'Sonstiges', value: 'other' },
];

export const CATEGORIES = [
  { id: 0, label: 'Mikronährstoffe', value: 'OM-Nährstoffe' },
  { id: 1, label: 'Pflanzenstoffe', value: 'Pflanzenstoffe' },
  { id: 2, label: 'Vitalpilze', value: 'Vitalpilze' },
  { id: 3, label: 'TCM | TEM', value: 'TCM/TEM' },
  { id: 4, label: 'Aminosäuren', value: 'Aminosäuren' },
  { id: 5, label: 'Bewegungsapparat', value: 'Bewegungsapparat' },
  { id: 6, label: 'Darm', value: 'Darm' },
  { id: 7, label: 'Energie + Stoffwechsel', value: 'Energiestoffwechsel' },
  { id: 8, label: 'Entspannung + Schlaf', value: 'Entspannung - Schlaf' },
  { id: 9, label: 'Frauengesundheit', value: 'Frauengesundheit' },
  { id: 10, label: 'Herz-Kreislauf', value: 'Herz-Kreislauf' },
  { id: 11, label: 'Entspannung', value: 'Entspannung' },
  { id: 12, label: 'Entzündung + Immunsystem', value: 'Entzündung - Immunsystem' },
  { id: 13, label: 'Psyche', value: 'Psyche' },
  { id: 14, label: 'Stoffwechsel', value: 'Stoffwechsel' },
  { id: 15, label: 'Well-Aging', value: 'Well-Aging' },
  { id: 16, label: 'Sonstiges', value: 'Sonstiges' },
];

export const GENDER_OPTIONS = [
  { label: 'Frau', value: 'female' },
  { label: 'Herr', value: 'male' },
];

export const ROUTES = {
  WIKI: '/wiki',
  WIKI_USERCONTENT_1: '/wikisanitas/file/:id',
  WIKI_USERCONTENT_2: '/wikisanitas/file/:id/:subId',
  WIKI_SANITAS: '/wikisanitas',
  REGISTER: '/register',
  REGISTER_SUCCESS: '/register-success',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  ACTIVATE_ACCOUNT: '/activate-account',
  PROFILE: '/profile',
  RESULTS: '/ergebnisse',
  CHANGE_PASSWORD: '/passwort-aendern',
  LOGIN: '/login',
  MY_MODULES: '/mein_campus',
  EXPERT_KNOWLEDGE: '/expertenwissen',
  HOME: '/',
  NOT_FOUND: '/404',
  USERCONTENT_1: '/file/:id',
  USERCONTENT_2: '/file/:id/:subId',
  CHANGE_PERMISSIONS: '/change-permissions',
  PAGES: '/pages',
  USERS: '/users',
  VIDEOS: '/videos',
  CREATE_VIDEO: '/create-video',
  VIEW_VIDEO: '/view-video/:id',
  EDIT_VIDEO: '/edit-video/:id',
  VIEW_USER: '/view-user/:id',
  CREATE_USER: '/create-user',
  EDIT_USER: '/edit-user/:id',
  SURVEYS: '/surveys',
  CREATE_FILE_1: '/create-file',
  CREATE_FILE_2: '/create-file/:id',
  CREATE_SURVEY: '/create-survey',
  VIEW_FILE_1: '/view-file/:id',
  VIEW_FILE_2: '/view-file/:id/:versionId',
  UPDATE_FILE: '/update-file/:id',
  VIEW_SURVEY_1: '/view-survey/:id',
  VIEW_SURVEY_2: '/view-survey/:id/:versionId',
  UPDATE_SURVEY: '/update-survey/:id',
  GROUPS: '/groups',
  VIEW_GROUP: '/view-group/:id',
  VIEW_PRODUCT: '/view-product/:id/:number',
  CAMPUS: '/campus',
  COMMUNITY: '/community',
  SHOP: 'https://service.forumviasanitas.org/',
};
