import { takeEvery } from "redux-saga/effects";
import { apiRequest } from "./index";
import api from "utils/api";
import * as videosActions from "actions/Videos";
import history from "utils/history";
import { get } from "lodash";
//import * as userActions from "../actions/User";
import { Slide, toast } from "react-toastify";

const getVideos = apiRequest.bind(null, videosActions.getVideos, api.getVideos);
const getVideo = apiRequest.bind(null, videosActions.getVideo, api.getVideo);
const createVideo = apiRequest.bind(
  null,
  videosActions.createVideo,
  api.createVideo
);
const updateVideo = apiRequest.bind(
  null,
  videosActions.updateVideo,
  api.updateVideo
);
const deleteVideo = apiRequest.bind(
  null,
  videosActions.deleteVideo,
  api.deleteVideo
);

function* handleChangeVideo(action) {
  if (get(action.payload, "options") === "redirect") {
    return yield history.push("/videos");
  } else {
    yield history.push(`/edit-video/${action.payload.id}`);
    const arg = { id: action.payload.id };
    yield apiRequest.apply(null, [videosActions.getVideo, api.getVideo, arg]);

    yield toast("Gespeichert", {
      transition: Slide,
      closeButton: true,
      closeOnClick: true,
      autoClose: true,
      position: "bottom-center",
      type: "success",
    });
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetVideosRequest() {
  yield takeEvery(videosActions.GET_VIDEOS.REQUEST, getVideos);
}

export function* watchGetVideoRequest() {
  yield takeEvery(videosActions.GET_VIDEO.REQUEST, getVideo);
}

export function* watchCreateVideoRequest() {
  yield takeEvery(videosActions.CREATE_VIDEO.REQUEST, createVideo);
}

export function* watchDeleteVideoRequest() {
  yield takeEvery(videosActions.DELETE_VIDEO.REQUEST, deleteVideo);
}

export function* watchDeleteVideoSuccess() {
  yield takeEvery(videosActions.DELETE_VIDEO.SUCCESS, getVideos);
}

export function* watchCreateVideoSuccess() {
  yield takeEvery(videosActions.CREATE_VIDEO.SUCCESS, handleChangeVideo);
}

export function* watchUpdateVideoRequest() {
  yield takeEvery(videosActions.UPDATE_VIDEO.REQUEST, updateVideo);
}

export function* watchUpdateVideoSuccess() {
  yield takeEvery(videosActions.UPDATE_VIDEO.SUCCESS, handleChangeVideo);
}
