import * as ActionTypes from '../actions/User';
import * as AuthActionTypes from '../actions/Auth';
import { get } from 'lodash';

const initialState = {
  users: [],
  user: null,
  usersPage: 0,
  usersPages: 0,
  usersClub: '',
  usersInitialPage: 0,
  usersPageSize: 100,
  tableFilter: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.SET_USERS_TABLE_FILTER:
      return { ...state, tableFilter: action.payload };
    case ActionTypes.SET_USERS_PAGE:
      return { ...state, usersInitialPage: action.payload };
    case ActionTypes.SET_USERS_PAGE_SIZE:
      return { ...state, usersPageSize: action.payload };
    case ActionTypes.USERS.SUCCESS:
      return { ...state, users: action.payload };
    case ActionTypes.GET_FILTERED_USERS.SUCCESS:
      return {
        ...state,
        users: get(action.payload, 'content', []),
        usersPage: get(action, 'payload.currentPage', 0),
        usersPages: get(action, 'payload.totalPages', 0),
      };
    case ActionTypes.GET_USER.SUCCESS:
      return { ...state, user: action.payload };
    case ActionTypes.DELETE_USER.SUCCESS:
      return {
        ...state,
        users: state.users.filter((el) => el.id !== action.payload),
      };
    case ActionTypes.UPDATE_USER.SUCCESS:
    case ActionTypes.CREATE_USER.SUCCESS:
      return state;
    default:
      return state;
  }
}
