import * as ActionTypes from "actions/Groups";
import * as AuthActionTypes from "actions/Auth";
import { get } from "lodash";

const initialState = {
  groups: [],
  group: {},
  userGroups: [],
  initialPage: 0,
  pageSize: 100,
  userGroupsPage: 0,
  userGroupsPages:0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.GET_USER_GROUPS.SUCCESS:
      return {
        ...state,
        userGroups: get(action, "payload", []),
      };
    case ActionTypes.GET_GROUPS.SUCCESS:
      return {
        ...state,
        groups: action.payload,
      };
    case ActionTypes.GET_GROUP.SUCCESS:
      return {
        ...state,
        group: action.payload,
      };
    case ActionTypes.GET_GROUP_BY_NAME.SUCCESS:
      return {
        ...state,
        group: action.payload,
      };
    case ActionTypes.GET_GROUP_USERS.SUCCESS:
      return {
        ...state,
        groupUsers: get(action.payload, "content"),
        userGroupsPage: get(action, "payload.currentPage", 0),
        userGroupsPages: get(action, "payload.totalPages", 0),
      };
    default:
      return state;
  }
}
