import jwtDecode from "jwt-decode";

export const checkTokenExpiration = () => {
  try {
    const token = localStorage.getItem("BC_TOKEN");
    if (jwtDecode(token).exp < Date.now() / 1000) {
      return "expired";
    }
    return token;
  } catch (err) {
    return null;
  }
};

export const checkRefreshTokenExpiration = () => {
  try {
    const token = localStorage.getItem("BC_REFRESH_TOKEN");
    if (jwtDecode(token).exp < Date.now() / 1000) {
      return "expired";
    }
    return token;
  } catch (err) {
    return null;
  }
};

export const saveToken = (token) => {
  window.localStorage && window.localStorage.setItem("BC_TOKEN", token);
};

export const saveRefreshToken = (token) => {
  window.localStorage && window.localStorage.setItem("BC_REFRESH_TOKEN", token);
};

export const clearTokens = () => {
  window.localStorage && window.localStorage.removeItem("BC_TOKEN");
  window.localStorage && window.localStorage.removeItem("BC_REFRESH_TOKEN");
};
