import { createRequestTypes, action } from "../utils/actions";
import { REQUEST, SUCCESS, FAILURE } from "../utils/constants";

export const GET_VIDEOS = createRequestTypes("GET_VIDEOS");
export const GET_VIDEO = createRequestTypes("GET_VIDEO");
export const CREATE_VIDEO = createRequestTypes("CREATE_VIDEO");
export const UPDATE_VIDEO = createRequestTypes("UPDATE_VIDEO");
export const DELETE_VIDEO = createRequestTypes("DELETE_VIDEO");

export const getVideos = {
  request: () => action(GET_VIDEOS[REQUEST]),
  success: (payload) => action(GET_VIDEOS[SUCCESS], { payload }),
  failure: (payload) => action(GET_VIDEOS[FAILURE], { payload }),
};

export const getVideo = {
  request: (id) => action(GET_VIDEO[REQUEST], { id }),
  success: (payload) => action(GET_VIDEO[SUCCESS], { payload }),
  failure: (payload) => action(GET_VIDEO[FAILURE], { payload }),
};

export const createVideo = {
  request: (data) => action(CREATE_VIDEO[REQUEST], { data }),
  success: (payload) => action(CREATE_VIDEO[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_VIDEO[FAILURE], { payload }),
};

export const updateVideo = {
  request: (id, data) => action(UPDATE_VIDEO[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_VIDEO[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_VIDEO[FAILURE], { payload }),
};

export const deleteVideo = {
  request: (id) => action(DELETE_VIDEO[REQUEST], { id }),
  success: (payload) => action(DELETE_VIDEO[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_VIDEO[FAILURE], { payload }),
};
