import { takeEvery, select } from "redux-saga/effects";
import { apiRequest } from "./index";
import api from "utils/api";
import * as groupsActions from "actions/Groups";
import history from "utils/history";

const getGroups = apiRequest.bind(null, groupsActions.getGroups, api.getGroups);
const getGroup = apiRequest.bind(null, groupsActions.getGroup, api.getGroup);
const getGroupByName = apiRequest.bind(
  null,
  groupsActions.getGroupByName,
  api.getGroupByName
);
const getUserGroups = apiRequest.bind(
  null,
  groupsActions.getUserGroups,
  api.getUserGroups
);
const getGroupUsers = apiRequest.bind(
  null,
  groupsActions.getGroupUsers,
  api.getGroupUsers
);
const createGroup = apiRequest.bind(
  null,
  groupsActions.createGroup,
  api.createGroup
);
const updateGroup = apiRequest.bind(
  null,
  groupsActions.updateGroup,
  api.updateGroup
);
const deleteGroup = apiRequest.bind(
  null,
  groupsActions.deleteGroup,
  api.deleteGroup
);
const addUserToGroup = apiRequest.bind(
  null,
  groupsActions.addUserToGroup,
  api.addUsersToGroup
);
const updateUserGroups = apiRequest.bind(
  null,
  groupsActions.updateUserGroups,
  api.updateUserGroups
);

function* handleChangeGroup() {
  yield history.push("/groups");
}

function* handleUserGroupsRefresh() {
  const getUserUuid = (state) => state.User.user.userUuid;
  const userUuid = yield select(getUserUuid);

  const arg = { id: userUuid };

  yield apiRequest.apply(null, [
    groupsActions.getUserGroups,
    api.getUserGroups,
    arg,
  ]);
}

/******************************************************************************/
/******************************* WATCHERS *************************************/

/******************************************************************************/

export function* watchGetGroupsRequest() {
  yield takeEvery(groupsActions.GET_GROUPS.REQUEST, getGroups);
}

export function* watchGetGroupRequest() {
  yield takeEvery(groupsActions.GET_GROUP.REQUEST, getGroup);
}

export function* watchGetGroupByNameRequest() {
  yield takeEvery(groupsActions.GET_GROUP_BY_NAME.REQUEST, getGroupByName);
}

export function* watchGetGroupUsersRequest() {
  yield takeEvery(groupsActions.GET_GROUP_USERS.REQUEST, getGroupUsers);
}

export function* watchGetUserGroupsRequest() {
  yield takeEvery(groupsActions.GET_USER_GROUPS.REQUEST, getUserGroups);
}

export function* watchCreateGroupRequest() {
  yield takeEvery(groupsActions.CREATE_GROUP.REQUEST, createGroup);
}

export function* watchUpdateGroupRequest() {
  yield takeEvery(groupsActions.UPDATE_GROUP.REQUEST, updateGroup);
}

export function* watchAddUserToGroupRequest() {
  yield takeEvery(groupsActions.UPDATE_GROUP.REQUEST, addUserToGroup);
}

export function* watchDeleteGroupRequest() {
  yield takeEvery(groupsActions.DELETE_GROUP.REQUEST, deleteGroup);
}

export function* watchDeleteGroupSuccess() {
  yield takeEvery(groupsActions.DELETE_GROUP.SUCCESS, getGroups);
}

export function* watchCreateGroupSuccess() {
  yield takeEvery(groupsActions.CREATE_GROUP.SUCCESS, getGroups);
}

export function* watchUpdateGroupSuccess() {
  yield takeEvery(groupsActions.UPDATE_GROUP.SUCCESS, handleChangeGroup);
}

export function* watchUpdateUserGroupsRequest() {
  yield takeEvery(groupsActions.UPDATE_USER_GROUPS.REQUEST, updateUserGroups);
}

export function* watchUpdateUserGroupsSuccess() {
  yield takeEvery(
    groupsActions.UPDATE_USER_GROUPS.SUCCESS,
    handleUserGroupsRefresh
  );
}
