import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const USERS = createRequestTypes('USERS');
export const GET_USER = createRequestTypes('GET_USER');
export const CREATE_USER = createRequestTypes('CREATE_USER');
export const UPDATE_USER = createRequestTypes('UPDATE_USER');
export const UPDATE_USER_STATUS = createRequestTypes('UPDATE_USER_STATUS');
export const DELETE_USER = createRequestTypes('DELETE_USER');
export const CREATE_USER_KEYCLOAK = createRequestTypes('CREATE_USER_KEYCLOAK');
export const CREATE_USER_ADDRESS = createRequestTypes('CREATE_USER_ADDRESS');
export const GET_FILTERED_USERS = createRequestTypes('GET_FILTERED_USERS');
export const UPDATE_USER_PASSWORD = createRequestTypes('UPDATE_USER_PASSWORD');
export const UPLOAD_USER_IMAGE = createRequestTypes('UPLOAD_USER_IMAGE');
export const DELETE_USER_IMAGE = createRequestTypes('DELETE_USER_IMAGE');
export const SET_USERS_PAGE = 'SET_USERS_PAGE';
export const SET_USERS_TABLE_FILTER = 'SET_USERS_TABLE_FILTER';
export const SET_USERS_PAGE_SIZE = 'SET_USERS_PAGE_SIZE';

export const setUsersPageSize = (payload) => ({
  type: SET_USERS_PAGE_SIZE,
  payload,
});

export const setUsersTableFilter = (payload) => ({
  type: SET_USERS_TABLE_FILTER,
  payload,
});

export const users = {
  request: () => action(USERS[REQUEST]),
  success: (payload) => action(USERS[SUCCESS], { payload }),
  failure: (payload) => action(USERS[FAILURE], { payload }),
};

export const getUser = {
  request: (id) => action(GET_USER[REQUEST], { id }),
  success: (payload) => action(GET_USER[SUCCESS], { payload }),
  failure: (payload) => action(GET_USER[FAILURE], { payload }),
};

export const getFilteredUsers = {
  request: (data, meta) => action(GET_FILTERED_USERS[REQUEST], { data, meta }),
  success: (payload) => action(GET_FILTERED_USERS[SUCCESS], { payload }),
  failure: (payload) => action(GET_FILTERED_USERS[FAILURE], { payload }),
};

export const createUser = {
  request: (data) => action(CREATE_USER[REQUEST], { data }),
  success: (payload) => action(CREATE_USER[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_USER[FAILURE], { payload }),
};

export const createUserKeycloak = {
  request: (data) => action(CREATE_USER_KEYCLOAK[REQUEST], { data }),
  success: (payload) => action(CREATE_USER_KEYCLOAK[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_USER_KEYCLOAK[FAILURE], { payload }),
};

export const updateUser = {
  request: (data) => action(UPDATE_USER[REQUEST], { data }),
  success: (payload) => action(UPDATE_USER[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_USER[FAILURE], { payload }),
};

export const updateUserStatus = {
  request: (data) => action(UPDATE_USER_STATUS[REQUEST], { data }),
  success: (payload) => action(UPDATE_USER_STATUS[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_USER_STATUS[FAILURE], { payload }),
};

export const deleteUser = {
  request: (data) => action(DELETE_USER[REQUEST], { data }),
  success: (payload) => action(DELETE_USER[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_USER[FAILURE], { payload }),
};

export const createUserAddress = {
  request: (id, data) => action(CREATE_USER_ADDRESS[REQUEST], { id, data }),
  success: (payload) => action(CREATE_USER_ADDRESS[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_USER_ADDRESS[FAILURE], { payload }),
};

export const updateUserPasswordByAdmin = {
  request: (data) => action(UPDATE_USER_PASSWORD[REQUEST], { data }),
  success: (payload) => action(UPDATE_USER_PASSWORD[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_USER_PASSWORD[FAILURE], { payload }),
};

export const uploadUserImage = {
  request: (data) => action(UPLOAD_USER_IMAGE[REQUEST], { data }),
  success: (payload) => action(UPLOAD_USER_IMAGE[SUCCESS], { payload }),
  failure: (payload) => action(UPLOAD_USER_IMAGE[FAILURE], { payload }),
};

export const deleteUserImage = {
  request: (data) => action(DELETE_USER_IMAGE[REQUEST], { data }),
  success: (payload) => action(DELETE_USER_IMAGE[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_USER_IMAGE[FAILURE], { payload }),
};
