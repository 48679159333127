import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import keycloak from 'utils/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import ResizeDetector from 'react-resize-detector';
import { useClearCache } from 'react-clear-cache';
import RoutesWithAuth from '../RoutesWithAuth';
import RoutesWithoutAuth from '../RoutesWithoutAuth';
import { BackToTopButton } from '../../components/Buttons/BackToTop';
import { ROUTES } from '../../utils/constants';

const Main = ({ userRole, location }) => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const userContentPattern = new RegExp(ROUTES.WIKI_USERCONTENT_1.replace(':id', '.*'), 'g');

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setScrollToTopVisible(true);
    } else {
      setScrollToTopVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isAuthNeeded = useMemo(
    () =>
      !(
        location.pathname === '/register' ||
        location.pathname === '/register-success' ||
        location.pathname.includes('/forgot-password') ||
        location.pathname.includes('/reset-password') ||
        location.pathname.includes('/activate-account') ||
        location.pathname.includes(ROUTES.WIKI_SANITAS) ||
        userContentPattern.test(location.pathname)
      ),
    [location.pathname]
  );

  useEffect(() => {
    window.onpageshow = function(event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, []);

  return (
    <>
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <div className={cx('app-container app-theme-gray')} style={userRole === 'user' ? { background: '#fff', position: 'relative' } : null}>
            {isAuthNeeded ? (
              <ReactKeycloakProvider
                authClient={keycloak}
                onTokens={(tokens) => {
                  localStorage.setItem('BC_TOKEN', tokens.token);
                  localStorage.setItem('BC_REFRESH_TOKEN', tokens.refreshToken);
                }}
                onEvent={(event, error) => {
                  if (event === 'onAuthSuccess') localStorage.setItem('BC_TOKEN', keycloak.token);
                }}
                initOptions={{ checkLoginIframe: true, onLoad: 'login-required', flow: 'standard' }}
              >
                <RoutesWithAuth />
              </ReactKeycloakProvider>
            ) : (
              <RoutesWithoutAuth />
            )}
            {scrollToTopVisible && <BackToTopButton />}
          </div>
        )}
      />
    </>
  );
};

const mapStateToProps = ({ UserRoles }) => ({
  userRole: UserRoles.userRole,
});

export default withRouter(connect(mapStateToProps)(Main));
