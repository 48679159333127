import React, { Component } from 'react';
import ErrorView from 'components/ErrorView';
import { connect } from 'react-redux';
// import { version } from 'config/environment';
// import { get } from 'lodash';

class ErrorHandler extends Component {
  state = {
    hasError: false,
  };
  componentDidCatch = async (error) => {
    // const res = await api.getIp();
    // const ip = get(res, 'response.ip', '');
    // const payload = {
    //   applicationName: "BC-Admin",
    //   applicationVersion: version,
    //   dateTime: new Date().toISOString(),
    //   fileName: "",
    //   // "ip": ip,
    //   level: "ERROR",
    //   message: `${error.name}: ${error.message}`,
    //   spanId: "",
    //   stackTrace: "",
    //   traceId: "",
    //   userAgent: window.navigator.userAgent,
    //   userName: get(this.props.user, "preferred_username"),
    // };
    this.setState({ hasError: true });
  };
  render() {
    if (this.state.hasError) return <ErrorView />;
    return this.props.children;
  }
}

const mapStateToProps = ({ Auth }) => ({
  user: Auth.user,
});

export default connect(mapStateToProps, null)(ErrorHandler);
